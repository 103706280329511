
.loading-indicator:before {
    content: "";
  
    background: #00000080;
  
    position: fixed;
  
    width: 100%;
  
    height: 100%;
  
    top: 0;
  
    left: 0;
  
    z-index: 1000;
  }
  
  .loading-indicator:after {
    content: " ";
  
    position: fixed;
  
    top: 40%;
  
    left: 45%;
  
    z-index: 10010;
  
    color: white;
  
    text-align: center;
  
    font-weight: bold;
  
    font-size: 1.2rem;
  
    background-image: url("../src/Assets/Pictures/loading.gif");
    background-size: cover;
    background-position: center;
  
    width: 100px;
  
    height: 100px;
  
    animation: spin 2s linear infinite;
  }