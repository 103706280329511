* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  background-color: #f3fbfa;
  padding-top: 40px;
  padding-bottom: 40px;
}
#navbarNav {
  margin-left: 30%;
}
.navbar-nav .nav-link {
  margin: 5px;
  font: Plus Jakarta Sans;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}

.navbar-nav .nav-link:hover {
  color: #0cb5a2 !important;
}

.nav-link {
  color: #605f5f !important;
}
.contactbtn {
  background: linear-gradient(180deg, #0cb5a2 0%, #11bfaa 51.5%, #1aa99b 100%);
  color: white;
  padding: 8px 20px 8px 20px;
  border-radius: 6px;
  border-style: none;
  font-weight: 600;
  text-decoration: none;
  border: 1px solid #0cb5a2;
  cursor: pointer;
}
.contactbtn:hover {
  /* border: 1px solid black !important; */
  color: #0cb5a2 !important;
  background: white !important;
}

@media screen and (min-width: 992px) {
  #navbarNav {
    margin-left: 15% !important;
  }
  .navbar-nav a {
    margin: 0px !important;
    font: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 15px;
  }
  .nav-item {
    margin-right: 20px !important;
  }
}
@media screen and (min-width: 1200px) {
  #navbarNav {
    margin-left: 28% !important;
  }
  .navbar-nav a {
    margin: 0px !important;
    font: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 15px;
  }
}
@media screen and (max-width: 992px) {
  #navbarNav {
    margin-left: 0% !important;
    margin-top: 30px !important;
  }
  .contactbtn {
    background: none;
    color: #0cb5a2 !important;
    padding-left: 4px;
    border-radius: 6px;
    border-style: none;
    font-weight: 700 !important;
    text-decoration: none;
    margin-top: 10px !important;
  }
  .hmecontbtn {
    margin-top: 8px !important;
    padding-bottom: 10px !important;
  }
  /* .navbar-collapse {
    border: 2px solid black !important;
  }
  .nav-item {
    border-bottom: 2px solid black;
  }
  .nav-item {
    border-bottom: 2px solid black;
  }
  .nav-item a {
    color: #0cb5a2 !important;
    font-weight: 700 !important;
  }
  .nav-item a:hover {
    background-color: red;
  } */
  .nav-item a {
    color: #0cb5a2 !important;
    font-weight: 700 !important;
  }
}
