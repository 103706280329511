body {
  font-family: "Plus Jakarta Sans", sans-serif;
  --color: #0bb4a1;
}

.card-text {
  color: rgba(92, 92, 92, 1);
  font-weight: 600;
  height: auto !important;
}

.section1 h1 {
  color: #5c5c5c;
  font-size: 85px !important;
  font-weight: 900;
  /* letter-spacing: -0.02em; */
  text-align: center;
}

.section1 {
  position: relative !important;
}

.section1 p {
  top: 0;
  font-size: 22px;
  color: #5c5c5c;
}

.fluid {
  background-color: #f3fbfa;
  position: relative;
}

.part1 {
  /* position: relative; */
  background-color: #f3fbfa;
  /* padding-bottom: 18%; */
}

.boxleft {
  transform: rotate(-14deg);
  position: absolute;
  left: 60px;
  top: 245px;
}

.boxright {
  transform: rotate(14deg);
  margin-left: 0%;
  position: absolute;
  bottom: 80px;
  right: 130px;
}

.part1 .arrowleft {
  position: absolute;
  bottom: 90px;
  left: 160px;
}

.part1 .arrowright {
  position: absolute;
  top: 580px;
  right: 420px;
}

.bannerlast {
  font-weight: 600;
  color: #5c5c5c;
  padding-top: 12%;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.part1 #nextline {
  color: #12c0ab;
}

.btns {
  display: flex;
  justify-content: center;
}

.btns p {
  margin-top: 15px;
  margin-right: 15px;
  font-size: 16px;
  font-weight: 700;
  /* line-height: 22.32px; */
  text-align: center;
}

.para {
  margin-left: 10px;
}

.play {
  margin-left: 20px;
}

.getbtn {
  background-color: #0bb4a1;
  padding: 6px 45px 8px 45px;
  color: white;
  border-style: none;
  border-radius: 7px;
  font-weight: 600;
}

.getbtn:hover {
  color: #0cb5a2 !important;
  background: white !important;
}


.plybtn {
  border: 1px solid #0bb4a1 !important;
  margin-bottom: 100px;
  position: relative;
  top: 15px;
  padding: 4px 8px 4px 9px;
  border-radius: 8px;
  color: #0bb4a1;
}

.number {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.num {
  font-size: 30px;
  font-weight: 900;
  font: Cabinet Grotesk;
  color: rgba(92, 92, 92, 1);
}

.name {
  font-size: 15px;
  font-weight: 600;
  font: Cabinet Grotesk;
  color: rgba(92, 92, 92, 1);
}

.searchhme {
  background-color: white !important;
}

.start {
}

@media screen and (max-width: 767px) {
  /* SELFNESSNESS */
  .selfcon h4 {
    /* margin-right: 0px !important; */
    margin-top: 20px !important;
    font-size: 20px !important;
    font-weight: 700;
    color: rgba(92, 92, 92, 1);
    text-align: center !important;
  }

  .selfcon2 h4 {
    /* margin-right: 0px !important; */
    margin-top: 20px !important;
    font-size: 20px;
    font-weight: 700;
    color: rgba(92, 92, 92, 1);
    text-align: center !important;
  }

  .selfcon p {
    padding: 0px !important;
    text-align: justify !important;
    font-size: 15px;
    color: rgba(100, 100, 100, 1);
  }

  .selfcon2 p {
    padding-right: 0px !important;
    text-align: justify;
    font-size: 15px;
    color: rgba(100, 100, 100, 1);
    line-height: 20px;
  }

  .selfcon {
    margin-left: 0px !important;
  }

  .selfcon2 {
    margin-left: 0px !important;
  }

  .selfness {
    display: flex;
    flex-direction: column !important;
    padding-left: 10px !important;
  }

  .selfnessness {
    display: flex;
    flex-direction: column !important;
  }

  .part1 {
    position: relative;
    background-color: #f3fbfa;
    background-color: cover;
  }

  .part1 .arrowright {
    display: none;
  }

  .part1 .arrowleft {
    display: none;
  }

  .boxleft {
    display: none;
  }

  .boxright {
    display: none;
  }

  .section1 h1 {
    color: #5c5c5c;
    font-weight: 900;
    font-size: 40px !important;
  }

  .section1 p {
    color: #5c5c5c;
    font-size: 18px;
  }

  .para {
    margin-left: 0px;
  }

  .ourstorybtn {
    margin: 10px;
    font-size: 13px !important;
    font-weight: 600;
  }

  .btns p {
    margin-top: 10px;
    margin-right: 15px;
    font-weight: 500;
  }

  .plybtn {
    top: 10px !important;
  }

  .getbtn {
    background-color: #0bb4a1;
    padding: 8px 30px 8px 20px !important;
    color: white;
    border-style: none;
    border-radius: 7px;
    margin-right: 10px !important;
  }

  .categoryinput {
    padding: 20px 60px 20px 50px !important;
    border: 2px solid #10bea9;
    border-radius: 30px;
  }

  .locationinput {
    margin-top: 10px;
    padding: 20px 50px 20px 60px !important;
    border: 2px solid #10bea9;
    border-radius: 30px !important;
  }

  .main-search {
    padding: 4px 8px 7px 40px !important;
    border: 2px solid #10bea9;
    border-radius: 30px;
    background-color: #10bea9;
    color: white;
  }

  .con1 {
    border: 1px solid #10bea9;
    padding: 3px 3px 5px 5px !important;
    border-radius: 30px;
    color: #5c5c5c;
    margin-right: 10px !important;
    margin-top: 10px;
  }

  .boxes {
    display: flex;
    flex-direction: column;
  }

  .secondline {
    display: flex;
    flex-direction: column;
    margin-top: 1px !important;
  }

  .thirdline {
    display: flex;
    flex-direction: column;
    margin-top: 1px !important;
  }

  .popularbox {
    margin: 40px;
  }

  .morecategory {
    padding: 10px 10px 10px 10px;
    border: 1px solid #0bb4a1;
    border-radius: 20px;
    color: #11bfaa;
    background-color: white;
  }

  .imgarrow1 {
    display: none;
  }

  .imgarrow2 {
    display: none;
  }

  .aboutus {
    padding-left: 0px !important;
    padding-right: calc(var(--bs-gutter-x) * 0);
  }

  .aboutus img {
    /* height: 250px !important;
    width: 280px !important; */
    /* margin-left: 15% !important; */
    border-radius: 60px !important;
    padding: 20px !important;
  }

  .aboutpara {
    text-align: justify;
    font-size: 15px;
    padding: 10px !important;
  }

  .aboutright h3 {
    color: #5c5c5c;
    font-size: 2.3rem;
    margin-left: 25% !important;
  }

  .aboutbtn {
    padding: 10px 40px 10px 40px;
    color: #10bea9;
    border: 1px solid #10bea9;
    background-color: white;
    border-radius: 20px;
    margin-left: 5px !important;
  }

  /* our values */

  .showalljobs {
    padding: 8px 25px 8px 28px !important;
    color: #11bfaa;
    border-radius: 20px;
    border: 1px solid #11bfaa;
    background-color: white;
    font-weight: 600;
    margin-left: 0px !important;
  }

  /*  */
  .play {
    margin-left: 0px;
  }

  .inputfield {
    border: none !important;
    border-radius: 0px !important;
    padding: 0px !important;
  }

  .con {
    margin-right: 0px !important;
  }

  .con2 {
    margin-right: 0px !important;
  }

  .con3 {
    margin-right: 0px !important;
  }

  .inputfield {
    display: flex !important;
    justify-content: center !important;
    flex-direction: row !important;
  }

  .category input:focus {
    border-color: red !important;
    color: rgb(11, 11, 11) !important;
  }

  .categoryicon {
    display: none;
  }

  .locationicon {
    display: none;
  }

  .searchicon {
    display: none;
  }

  .searchbtn p {
    margin-bottom: 0px !important;
    margin-left: 0px;
  }

  .location input {
    color: #08080866;
    font-weight: 700 !important;
    border-right: 2px solid #12c0ab;
    border: 2px solid #0bb4a1 !important;
    margin-top: 20px;
    border-radius: 30px !important;
  }

  .category input {
    color: #f6f0f066;
    font-weight: 700 !important;
    border: 2px solid #12c0ab !important;
    border-radius: 30px !important;
  }

  .location input:focus {
    border-color: red !important;
    color: rgb(11, 11, 11) !important;
  }

  .smalls {
    display: flex !important;
    justify-content: space-between !important;
  }

  .newsbox {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .searchbtn {
    background-color: #0bb4a1;
    padding: 5px 20px 7px 20px !important;
    border-radius: 30px !important;
    border: 1px solid #0bb4a1;
    color: white;
    margin-top: 20px;
  }

  .number {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .num {
    font-size: 30px !important;
    font-weight: 900;
    font: Cabinet Grotesk;
    color: rgba(92, 92, 92, 1);
  }

  .name {
    font-size: 15px !important;
    font-weight: 600;
    font: Cabinet Grotesk;
    color: rgba(92, 92, 92, 1);
  }

  .service {
    padding: 0px !important;
  }

  /* OYRSERVICE1 */
  .ourservice1 h3 {
    color: #5c5c5c;
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center !important;
  }

  .ourservice1 span {
    color: #12c0ab;
  }

  .ourservicepara {
    text-align: justify !important;
    font-size: 16px;
    padding: 0px !important;
    color: rgba(100, 100, 100, 1);
    letter-spacing: 0px;
  }

  .ourserviceleft h5 {
    font-size: 14px !important;
    font-weight: 700 !important;
    color: rgba(92, 92, 92, 1);
    /* text-align: center !important; */
    margin: 0px !important;
    letter-spacing: 0px !important;
  }

  .ourserviceleft {
    position: relative;
    margin-top: 30px !important;
    padding-left: 33px !important;
  }

  .ourserviceleft p {
    font-size: 15px;
    color: rgba(100, 100, 100, 1);
    letter-spacing: 0px;
    margin-left: 0px;
    /* padding: 0px; */
    /* text-align: center !important; */
  }

  .selfimg {
    /* height: 150px; */
    /* width: 10px; */
    /* margin-left: 1px; */
    float: left;
  }

  .tick {
    display: block;
  }

  .morebtn {
    border-radius: 20px;
    color: #10bea9;
    background-color: #10bea90d;
    border: 1px solid #10bea9;
    font-weight: 500;
    margin-top: 0px !important;
    margin-left: 0% !important;
  }

  .prob1 {
    margin-left: 0px !important;
  }

  #newsboxtop {
    margin-top: 40px !important;
  }

  #newsboxtop2 {
    margin-top: 30px !important;
  }

  #newsboxtop3 {
    margin-top: 30px !important;
  }

  #left {
    margin-right: 50% !important;
  }

  .cardavail {
    border: 2px solid white;
    box-shadow: 0px 2px 50px 0px #0000001a;
    margin-left: 0px !important;
    padding: 15px;
  }

  /* .subscribes {
    width: 380px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: none !important;
    padding: 5px;
    border-radius: 10px !important;
  }
  .subscribe {
    display: flex;
    justify-content: center;
  }

  .subscribes input {
    border-style: none;
    width: 240px !important;
    margin-left: 50px !important;
    margin-bottom: 2px;
    margin-top: 10px !important;
    border-radius: 40px;
    padding: 15px;
    border: 2px solid #10bea9 !important  ;
  } */

  /* .subscribes button {
    width: 100px;
    border-style: none;
    border: 2px solid #0bb4a1;
    padding: 5px 15px 5px 15px;
    background-color: #0bb4a1;
    color: white;
    border-radius: 40px !important;
    font-weight: 400;
    font-size: 13px;
    margin-left: 0px;
    margin-top: 20px !important;
  } */
  .subscribes input:focus {
    outline: none;
    border: none;
    color: black;
  }

  .owl-dot {
    display: none !important;
  }

  .part3cont p {
    color: #646464;
    padding: 0px !important;
  }

  .cardbody3 p {
    color: #646464;
    padding: 0px 70px;
  }

  .cardbody3 {
    margin-top: 10px !important;
  }

  .aboutcon {
    padding-bottom: 20px !important;
  }

  .aboutushme {
    margin-top: 0px !important;
  }

  .aboutrighthme {
    margin-top: 0px !important;
  }

  .homeourvalues {
    background-color: rgba(243, 251, 250, 1);
    margin-top: 20px !important;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .homevalues h3 {
    color: rgba(92, 92, 92, 1);
    font-size: 2.3rem;
    font-weight: 700;
    margin-top: 20px !important;
  }

  .paddrght {
    padding-right: calc(var(--bs-gutter-x) * 0) !important;
  }

  .tick {
    position: absolute;
    left: 6px !important;
    padding-left: 10px;
    background-color: #0bb4a1;
    color: white;
    padding: 3px 4px 3px 4px;
    border-radius: 50%;
    aspect-ratio: 0;
  }

  .lookingmain {
    height: auto;
    position: relative;
    margin-top: 0px !important;
  }

  .subscribes input {
    border-style: none;
    width: 230px;
    margin-left: 10px;
    margin-bottom: 2px;
    padding: 5px !important;
  }

  .searchrowhme {
    border: none;
    padding: 8px;
    background-color: white !important;
    border-radius: 45px;
    justify-content: center !important;
  }

  .subscribes button {
    border-style: none;
    border: 2px solid #0bb4a1;
    padding: 7px !important;
    background-color: #0bb4a1;
    color: white;
    border-radius: 30px;
    font-weight: 600;
    font-size: 13px;
  }
}

.end767 {
}

@media screen and (min-width: 768px) {
  .searchrowhme {
    border: none;
    padding: 8px;
    background-color: white !important;
    border-radius: 45px;
    justify-content: center !important;
  }

  .part1 .arrowright {
    display: none !important;
  }

  .part1 .arrowleft {
    display: none;
  }

  .boxleft {
    display: none;
  }

  .boxright {
    display: none;
  }

  .imgarrow1 {
    display: none;
  }

  .imgarrow2 {
    display: none;
  }

  /* INPUT */
  /* .inputfield {
    border: 2px solid #0bb4a1;
    border-radius: 40px;
    padding: 10px !important;
  }
  .category input {
    color: #f6f0f066;
    font-weight: 600;
    margin-left: 3px;
    border-style: none !important;
    padding: 12px;
    width: 180px !important;
    border-right: none !important;
  }
  .location input {
    font-weight: 600;
    border-style: none;
    padding: 10px;
    display: flex;
    flex-direction: column !important;
    width: 160px !important;
  }
  .searchbtn {
    background-color: #0bb4a1;
    padding: 3px 10px 4px 10px !important;
    border-radius: 20px !important;
    border: 1px solid #0bb4a1;
    color: white;
    padding-right: 10px !important;
    margin-top: 5px;
  }

  .searchicon {
    display: none;
  } */
  .searchbtn p {
    margin-bottom: 0px !important;
    margin-left: 0px;
  }

  .con1 {
    border: 1px solid #10bea9;
    padding: 3px 3px 5px 5px !important;
    border-radius: 30px;
    color: #5c5c5c;
    margin-right: 10px !important;
    margin-top: 10px;
  }

  .newsbox {
    display: flex !important;
    justify-content: center !important;
    flex-direction: row !important;
  }

  #newsbox3 {
    margin-left: 25%;
    margin-top: 30px;
  }

  .service {
    display: flex !important;
  }

  .morebtn {
    border-radius: 20px;
    color: #10bea9;
    background-color: #10bea90d;
    border: 1px solid #10bea9;
    font-weight: 500;
    margin-top: 50px !important;
  }

  .card-text {
    color: rgba(92, 92, 92, 1);
    font-weight: 600;
    height: 90px !important;
  }

  .showalljobs {
    padding: 8px 25px 8px 28px !important;
    color: #11bfaa;
    border-radius: 20px;
    border: 1px solid #11bfaa;
    background-color: white;
    font-weight: 600;
    margin-left: 41% !important;
  }

  #center3 {
    margin-left: 0% !important;
  }

  #center6 {
    margin-left: 0% !important;
  }

  /* ABOUT US */

  .aboutus {
    padding-left: 0px !important;
  }

  .aboutus img {
    /* height: 250px !important;
    width: 380px !important; */
    /* margin-left: 25% !important; */
    border-radius: 40px;
  }

  .aboutpara {
    text-align: center !important;
    font-size: 15px;
    padding-right: 0px !important;
  }

  .aboutright h3 {
    color: #5c5c5c;
    font-size: 2.3rem;
    margin-left: 40% !important;
  }

  .aboutbtn {
    padding: 10px 40px 10px 40px;
    color: #10bea9;
    border: 1px solid #10bea9;
    background-color: white;
    border-radius: 20px;
    margin-left: 40% !important;
  }

  .smalls {
    display: flex !important;
    justify-content: space-between !important;
    color: rgba(92, 92, 92, 1) !important;
  }

  /* SELFNESS */
  .selfcon h4 {
    margin-right: 0px !important;
    margin-top: 20px !important;
    font-size: 20px;
    font-weight: 700;
    color: rgba(92, 92, 92, 1);
    text-align: center !important;
  }

  .selfcon2 h4 {
    margin-left: 220px !important;
    margin-top: 20px !important;
    font-size: 20px;
    font-weight: 700;
    color: rgba(92, 92, 92, 1);
  }

  .selfcon p {
    padding: 0px 40px 0px 40px !important;
    text-align: justify !important;
    font-size: 15px;
    color: rgba(100, 100, 100, 1);
  }

  .selfcon2 p {
    padding-right: 40px !important;
    text-align: justify;
    font-size: 15px;
    color: rgba(100, 100, 100, 1);
    line-height: 20px;
  }

  .selfcon {
    margin-left: 20px;
  }

  .selfcon2 {
    margin-left: 30px;
  }

  .selfness {
    display: flex;
    flex-direction: column !important;
  }

  .selfnessness {
    display: flex;
    flex-direction: column !important;
  }

  .section1 h1 {
    color: #5c5c5c;
    font-size: 70px !important;
    font-weight: 900;
    text-align: center;
  }

  .ourserviceleft {
    position: relative;
    margin-top: 30px !important;
    padding-left: 40px !important;
  }

  .tick {
    position: absolute;
    left: 0px;
    background-color: #0bb4a1;
    color: white;
    padding: 3px 4px 3px 4px;
    border-radius: 50%;
    aspect-ratio: 0 !important;
  }

  #newsboxtop3 {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }

  #left {
    margin-right: 50% !important;
  }

  .owl-dot {
    display: none !important;
  }

  .part3cont p {
    color: #646464;
    padding: 0px !important;
  }

  .cardbody3 p {
    color: #646464;
    padding: 0px 20px !important;
  }

  .subscribes input {
    border-style: none;
    width: 230px;
    margin-left: 10px;
    margin-bottom: 2px;
    padding: 5px !important;
  }

  .searchrowhme {
    border: none;
    padding: 8px;
    background-color: white !important;
    border-radius: 45px;
    justify-content: center !important;
  }

  .subscribes button {
    border-style: none;
    border: 2px solid #0bb4a1;
    padding: 2px 16px 2px 16px !important;
    background-color: #0bb4a1;
    color: white;
    border-radius: 30px;
    font-weight: 600;
    font-size: 13px;
  }
}

.maxwidth {
}

@media screen and (min-width: 992px) {
  .searchrowhme {
    border: 2px solid #0bb4a1 !important;
  }

  .section1 h1 {
    color: #5c5c5c;
    font-size: 80px !important;
    font-weight: 900;
    text-align: center;
  }

  .searchbtn {
    background-color: #0bb4a1;
    padding: 7px 20px 7px 15px !important;
    border-radius: 20px;
    border: 1px solid #0bb4a1;
    color: white;
    margin-top: 6px;
    margin-right: 0px;
    display: flex;
    align-items: center;
  }

  .searchicon {
    color: white;
    display: block;
  }

  .category input {
    color: #f6f0f066;
    font-weight: 600;
    margin-left: 3px;
    border-style: none;
    padding: 12px;
    width: 240px !important;
    border-right: 2px solid #12c0ab !important;
  }

  .location input {
    font-weight: 600;
    border-style: none;
    padding: 10px;
    width: 240px !important;
    margin-left: 0px !important;
  }

  .searchbtn {
    background-color: #0bb4a1;
    padding: 8px 15px 8px 10px !important;
    border-radius: 20px !important;
    border: 1px solid #0bb4a1;
    color: white;
    margin-top: 4px;
  }

  .newsbox {
    display: flex !important;
    justify-content: center !important;
    flex-direction: row !important;
  }

  #newsbox3 {
    margin-left: 0%;
    margin-top: 0px;
  }

  #center3 {
    margin-left: 0% !important;
  }

  #center6 {
    margin-left: 0% !important;
  }

  /* ABOUT US */
  .aboutus {
    padding-left: 0px !important;
  }

  .aboutus img {
    /* height: 250px !important;
    width: 380px !important; */
    /* margin-left: 10% !important; */
    border-radius: 40px;
  }

  .aboutpara {
    text-align: justify !important;
    font-size: 15px;
    padding-right: 0px !important;
  }

  .aboutright h3 {
    color: #5c5c5c;
    font-size: 2.3rem;
    margin-left: 0% !important;
  }

  .aboutbtn {
    padding: 10px 40px 10px 40px;
    color: #10bea9;
    border: 1px solid #10bea9;
    background-color: white;
    border-radius: 20px;
    margin-left: 0% !important;
  }

  .smalls {
    display: flex !important;
    justify-content: center !important;
    color: rgba(92, 92, 92, 1) !important;
    padding-left: 25px;
    padding-right: 25px;
  }

  .fulltime {
    border: 1px solid #10bea9 !important;
    padding: 10px 10px 8px 10px !important;
    border-radius: 6px;
    margin-right: 5px;
    font-weight: 700;
    color: #12c0ab;
  }

  /* SELFNESS */
  .selfcon h4 {
    margin: 0px !important;
    margin-top: 20px !important;
    font-size: 20px;
    font-weight: 700;
    color: rgba(92, 92, 92, 1);
    text-align: center !important;
  }

  .selfcon2 h4 {
    margin: 0px !important;
    margin-top: 20px !important;
    font-size: 20px;
    font-weight: 700;
    color: rgba(92, 92, 92, 1);
    text-align: center !important;
  }

  .selfcon p {
    padding-right: 0px !important;
    margin-top: 10px !important;
    text-align: justify !important;
    font-size: 15px;
    color: rgba(100, 100, 100, 1);
  }

  .selfcon2 p {
    padding-right: 50px;
    margin-top: 10px !important;
    text-align: justify;
    font-size: 15px;
    color: rgba(100, 100, 100, 1);
    line-height: 20px;
  }

  .selfcon {
    margin-left: 20px;
  }

  .selfcon2 {
    margin-left: 30px;
  }

  .selfness {
    display: flex;
    flex-direction: column !important;
  }

  .selfnessness {
    display: flex;
    flex-direction: column !important;
  }

  #newsboxtop3 {
    margin-top: 0px !important;
    margin-left: 0% !important;
  }

  #left {
    margin-right: 0% !important;
  }

  .owl-dot {
    display: none !important;
  }

  .card-text {
    color: rgba(92, 92, 92, 1);
    font-weight: 600;
    height: 90px !important;
  }

  .subscribes input {
    border-style: none;
    width: 230px;
    margin-left: 10px;
    margin-bottom: 2px;
    padding: 5px !important;
  }

  .searchrowhme {
    border: none;
    padding: 8px;
    background-color: white !important;
    border-radius: 45px;
    justify-content: center !important;
  }

  .subscribes button {
    border-style: none;
    border: 2px solid #0bb4a1;
    padding: 2px 16px 2px 16px !important;
    background-color: #0bb4a1;
    color: white;
    border-radius: 30px;
    font-weight: 600;
    font-size: 13px;
  }
}

@media screen and (min-width: 1200px) {
  .part1 .arrowright {
    position: absolute;
    display: block !important;
    top: 100% !important;
    right: 30% !important;
  }

  .part1 .arrowleft {
    position: absolute;
    display: block !important;
    top: 78% !important;
    left: 11% !important;
  }

  .boxleft {
    position: absolute;
    display: block !important;
    top: 32% !important;
    width: 200px;
    left: 6% !important;
  }

  .boxright {
    position: absolute;
    display: block !important;
    top: 60% !important;
    width: 200px;
    right: 8% !important;
  }

  .imgarrow1 {
    display: block !important;
    left: 3% !important;
    top: 40% !important;
  }

  .imgarrow2 {
    display: block !important;
    right: 10% !important;
  }

  .category input {
    color: #f6f0f066;
    font-weight: 600;
    margin-right: 20px !important;
    border-style: none;
    padding: 12px;
    width: 320px !important;
    border-right: 2px solid #12c0ab !important;
  }

  .location input {
    font-weight: 600;
    border-style: none;
    padding: 10px;

    /* width: 310px !important; */
    margin-left: 25px !important;
  }

  .searchbtn {
    background-color: #0bb4a1;
    padding: 8px 15px 8px 10px !important;
    border-radius: 20px !important;
    border: 1px solid #0bb4a1;
    color: white;
    margin-left: 20px !important;
    margin-top: 4px;
  }

  .locationicon {
    background-color: #0bb4a1;
    aspect-ratio: 1;
    border-radius: 50px;
    color: white;
    padding: 14px;
    margin-left: 30px;
  }

  #center3 {
    margin-left: 0% !important;
  }

  #center6 {
    margin-left: 0% !important;
  }

  .newsbox {
    display: flex !important;
    justify-content: center !important;
    flex-direction: row !important;
    align-items: center !important;
  }

  .aboutus img {
    /* height: 250px !important;
    width: 380px !important; */
    /* margin-left: 20% !important; */
    border-radius: 40px;
  }

  .aboutpara {
    text-align: justify !important;
    font-size: 15px;
    padding-right: 100px !important;
  }

  .smalls {
    display: flex !important;
    justify-content: space-between !important;
    color: rgba(92, 92, 92, 1) !important;
    padding: 0px 0px !important;
  }

  /* SELFNESS */
  .selfcon h4 {
    margin-left: 0px !important;
    margin-top: 0px !important;
    font-size: 20px;
    font-weight: 700;
    color: rgba(92, 92, 92, 1);
    text-align: start !important;
  }

  .selfcon2 h4 {
    margin-left: 0px !important;
    margin-top: 0px !important;
    font-size: 20px;
    font-weight: 700;
    color: rgba(92, 92, 92, 1);
    text-align: start !important;
  }

  .selfcon p {
    margin-left: 0px !important;
    margin-top: 10px !important;
    padding: 0px !important;
    text-align: justify !important;
    font-size: 15px;
    color: rgba(100, 100, 100, 1);
  }

  .selfcon2 p {
    padding: 0px !important;
    margin-top: 10px !important;
    text-align: justify;
    font-size: 15px;
    color: rgba(100, 100, 100, 1);
    line-height: 20px;
  }

  .selfness {
    display: flex;
    flex-direction: row !important;
  }

  .selfnessness {
    display: flex;
    flex-direction: row !important;
  }

  .fulltime {
    border: 1px solid #10bea9;
    padding: 12px 20px 12px 20px;
    border-radius: 6px;
    margin-right: 0px !important;
    font-weight: 700;
    color: #12c0ab;
  }

  .Onsite {
    border: 1px solid #10bea9;
    padding: 12px 20px 12px 20px;
    border-radius: 6px;
    margin-right: 0px !important;
  }

  .searchicon {
    color: white;
    margin-right: 18px !important;
  }

  .searchbtn p {
    margin-bottom: 0px !important;
    margin-left: 10px;
  }

  .part3cont p {
    color: #646464;
    padding: 0px !important;
  }

  .cardbody3 p {
    color: #646464;
    padding: 0px 50px !important;
  }

  .card-text {
    color: rgba(92, 92, 92, 1);
    font-weight: 600;
    height: auto !important;
  }
}

.s1400 {
}

@media screen and (min-width: 1400px) {
  /* .fluid {
    position: relative !important;
  }
  .part1 .arrowright {
    position: absolute;
    display: block !important;
    top: 75% !important;
    right: 35% !important;
  }
  .part1 .arrowleft {
    position: absolute;
    display: block !important;
    top: 55% !important;
    left: 18% !important;
  }
  .boxleft {
    position: absolute;
    display: block !important;
    top: 25% !important;
    width: 200px;
    left: 13% !important;
  }
  .boxright {
    position: absolute;
    display: block !important;
    top: 40% !important;
    width: 200px;
    right: 15% !important;
  }
  .imgarrow1 {
    display: block !important;
    left: 5% !important;
    top: 40% !important;
  }
  .imgarrow2 {
    display: block !important;
    right: 15% !important;
  } */
  .aboutus img {
    /* height: 280px !important;
    width: 380px !important; */
    /* margin-left: 30% !important; */
    border-radius: 40px;
  }

  .category input {
    color: #f6f0f066;
    font-weight: 600;
    margin-right: 20px !important;
    border-style: none;
    padding: 12px;
    width: 360px !important;
    border-right: 2px solid #12c0ab !important;
  }

  .location input {
    font-weight: 600;
    border-style: none;
    padding: 10px;

    /* width: 310px !important; */
    margin-right: 35px !important;
  }

  .searchbtn {
    background-color: #0bb4a1;
    padding: 12px 18px 12px 19px !important;
    border-radius: 30px !important;
    border: 1px solid #0bb4a1;
    color: white;
    margin-left: 35px !important;
    margin-top: 0px;
  }

  .searchbtn p {
    margin-bottom: 0px !important;
    margin-left: 10px;
  }

  .searchicon {
    color: white;
    margin-right: 18px !important;
  }
}

.owlback {
  background: linear-gradient(180deg, #10bea9 0%, #11bfaa 52.5%, #19a99c 100%);
  padding: 20px;
}

.owlimage {
  height: 100px;
  width: 150px !important;
  margin: auto;
  object-fit: contain;
  border-radius: 2px;
  background-color: "#fff";
  


}
.owl-stage-outer{
  overflow: visible;
}

.part3cont h2 {
  font-weight: 600;
  color: rgba(92, 92, 92, 1);
}

.part3cont p {
  color: #646464;
  padding: 0px 123px;
}

.part3cont span {
  color: #10bea9;
  font-weight: 600;
}

.cardimg img {
  height: 130px;
  width: 160px;
  border-radius: 10px;
}

.cardbody3 h4 {
  color: #15b2a1;
  font-weight: 700;
}

.cardbody3 p {
  color: #646464;
  padding: 0px 70px;
}

.box {
  display: flex;
  flex-direction: row;
}

/* OUR SERVICE */
.service {
  /* border: Mixed solid rgba(16, 190, 169, 1); */
  background-color: rgb(241, 249, 249);
  padding: 60px;
}

.ourservice1 h3 {
  color: #5c5c5c;
  font-size: 2.2rem;
  font-weight: 700;
}

.ourservice1 span {
  color: #12c0ab;
}

.ourservicepara {
  text-align: justify;
  font-size: 15px;
  padding-right: 115px;
  color: rgba(100, 100, 100, 1);
}

.ourserviceleft h5 {
  font-size: 18px;
  letter-spacing: -1px;
  font-weight: 600;
  color: rgba(92, 92, 92, 1);
}

.ourserviceleft {
  position: relative;
}

.ourserviceleft p {
  font-size: 15px;
  color: rgba(100, 100, 100, 1);
  letter-spacing: 0px;
}

.tick {
  position: absolute;
  left: 0px;
  padding-left: 10px;
  background-color: #0bb4a1;
  color: white;
  padding: 3px 4px 3px 4px;
  border-radius: 50%;
  aspect-ratio: 0;
}

.morebtn {
  border-radius: 20px;
  color: #10bea9;
  background-color: #10bea90d;
  border: 1px solid #10bea9;
  font-weight: 500;
  margin-top: 0px !important;
}

.morebtn:hover {
  color: white !important;
  background-color: #19a99c;
}

/* ḷooking for a job */
.dfghjm {
}

.inputfield {
  background-color: white;
  border: 2px solid #0bb4a1;
  border-radius: 40px;
  padding: 10px;
}

.category input {
  color: #f6f0f066;
  font-weight: 600;
  /* margin-right: 70px; */
  border-style: none;
  padding: 12px;
  width: 300px;
  border-right: 2px solid #12c0ab;
}

.category input:focus {
  outline: none;
  border: none;
  color: black;
}

.categoryicon {
  background-color: #0bb4a1;
  aspect-ratio: 1;
  border-radius: 50px;
  color: white;
  padding: 15px;
}

.locationicon {
  background-color: #0bb4a1;
  aspect-ratio: 1;
  border-radius: 50px;
  color: white;
  padding: 14px;
}

.location input {
  font-weight: 600;
  border-style: none;
  padding: 10px;
  width: 300px;
}

.location input:focus {
  outline: none;
  border: none;
  color: black;
}

.searchbtn {
  background-color: #0bb4a1;
  padding: 7px 15px 7px 15px;
  border-radius: 20px;
  border: 1px solid #0bb4a1;
  color: white;
}

.searchicon {
  color: white;
  margin-right: 8px;
}

/*  */
.lookingmain {
  height: auto;
  position: relative;
}

.looking h2 {
  color: #5c5c5c;
  font-weight: 700;
}

#head {
  color: #10bea9;
  font-weight: 700;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

/* BOXES */
.dfghjkl {
}

.con1 {
  border: 2px solid #0bb4a1;
  width: auto;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 10px 5px 9px;
}

.para2 {
  margin-left: 10px;
  margin-bottom: 0px;
  background-color: #10bea9;
  padding: 2px 10px 2px 8px;
  border-radius: 10px;
  font-size: 10px;
}

.para1 {
  margin-bottom: 0px;
  font-size: 11px;
  font-weight: 600;
}

.install {
}

.morecategory {
  padding: 10px 30px 10px 30px;
  border: 1px solid #0bb4a1;
  font-weight: 600;
  border-radius: 20px;
  color: #11bfaa;
  /* border-style: none; */
  background-color: white;
  text-decoration: none;
}

.morecategory:hover {
  color: white !important;
  background-color: #10bea9;
}

/* AVAILABLE JOBS */
.availablejobs {
  height: auto;
  margin-top: 35px;
}

.availablehead h3 {
  font-size: 2rem;
  font-weight: 650;
  color: #5c5c5c;
}

.smallavil {
  font-size: 2rem;
  font-weight: 650;
  color: #0bb4a1;
}

.availpara {
  color: #646464;
}

.recent {
  padding: 7px 30px 8px 30px;
  border-style: none;
  color: #10bea9 !important;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #10bea9;
  font-weight: 600;
}

.recent:hover {
  color: white !important;
  background-color: #10bea9;
}

.popular {
  padding: 7px 30px 8px 30px;
  border-style: none;
  color: #10bea9 !important;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #10bea9;
  font-weight: 600;
}

.popular:hover {
  color: white !important;
  background-color: #19a99c;
}

/* vailcard */
.availablebox {
  padding-left: 10px;
}

.cardavail {
  border: 2px solid white;
  box-shadow: 0px 2px 50px 0px #0000001a;
  margin-left: 6px;
  padding: 15px;
}

.smalls {
  display: flex;
  justify-content: space-evenly;
  color: rgba(92, 92, 92, 1);
}

.applydiv {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.employee {
  position: absolute;
  top: 20px;
  right: 80px;
}

.cardavailhead {
  margin-top: 20px;
  color: rgba(92, 92, 92, 1);
  font-weight: 600;
}

.cardavailpara {
  font-size: 15px;
  color: rgba(92, 92, 92, 1);
  font-weight: 700;
}

.applybtns {
  border-radius: 6px;
  padding: 10px 25px 12px 25px;
  color: white;
  background-color: #11bfaa;
  border-style: none;
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
}

.applybtns:hover {
  background-color: white;
  color: #0bb4a1;
}

.applyies {
  margin-top: 17px;
  font-weight: 600;
  font-size: 14px;
  color: rgba(92, 92, 92, 1);
}

.fulltime {
  border: 1px solid #10bea9;
  padding: 12px 20px 12px 20px;
  border-radius: 6px;
  margin-right: 5px;
  font-weight: 700;
  color: #12c0ab;
}

.Onsite {
  border: 1px solid #10bea9;
  padding: 12px 20px 12px 20px;
  border-radius: 6px;
  margin-right: 5px;
}

.thousands {
  border: 1px solid #10bea9;
  padding: 10px 20px 12px 20px;
  border-radius: 6px;
  font-weight: 700;
  color: #12c0ab;
}

.showalljobs {
  padding: 8px 35px 8px 28px;
  color: #11bfaa;
  border-radius: 20px;
  border: 1px solid #11bfaa;
  background-color: white;
  font-weight: 600;
  text-decoration: none;
}

.showalljobs:hover {
  color: white !important;
  background-color: #19a99c;
}

/* OURVALUES */
.ourvalues {
  background-color: #f3fbfa;
  height: auto;
  padding-bottom: 80px;
}

.ourvaluessec1 h3 {
  font-weight: 700;
  font-size: 2rem;
  color: #5c5c5c;
}

.ourhead {
  color: #10bea9;
  font-weight: 700;
}

/* selfimag */
.selfimg {
  height: 150px;
  /* width: 10px; */
  /* margin-left: 1px; */
}

.selfcon h4 {
  margin-right: 290px;
  font-size: 20px;
  font-weight: 700;
  color: rgba(92, 92, 92, 1);
}

.selfcon2 h4 {
  margin-right: 290px;
  font-size: 20px;
  font-weight: 700;
  color: rgba(92, 92, 92, 1);
}

.selfcon p {
  padding-right: 100px;
  text-align: justify;
  font-size: 15px;
  color: rgba(100, 100, 100, 1);
  line-height: 20px;
}

.selfcon2 p {
  padding-right: 50px;
  text-align: justify;
  font-size: 15px;
  color: rgba(100, 100, 100, 1);
  line-height: 20px;
}

.selfcon {
  margin-left: 20px;
}

.selfcon2 {
  margin-left: 20px;
}

.selfness {
  display: flex;
  flex-direction: row;
}

.selfnessness {
  display: flex;
  flex-direction: row;
}

/* About us */
.aboutus {
  padding-left: 80px;
}

.aboutus img {
  /* width: 400px; */
  border-radius: 40px;
}

.aboutcon {
  padding-bottom: 80px;
}

.aboutright h3 {
  color: #5c5c5c;
  font-size: 2.3rem;
  font-weight: 700;
}

.abouthead {
  color: #0bb4a1;
  font-weight: 700;
}

.aboutpara {
  text-align: justify;
  font-size: 15px;
  padding-right: 90px;
}

.aboutbtn {
  padding: 10px 40px 10px 40px;
  color: #10bea9;
  border: 1px solid #10bea9;
  background-color: white;
  border-radius: 20px;
  font-weight: 600;
}

.aboutbtn:hover {
  color: white !important;
  background-color: #19a99c;
}

/* news help */
.newshelp {
  background-color: #f3fbfa;
}

.news h3 {
  color: #5c5c5c;
  font-size: 2rem;
  font-weight: 700;
}

.newshead {
  color: #15b2a1;
  font-weight: 700;
}

/* newsbox */
/* .newshappy {
  box-shadow: 0px 34px 33px -23px #161c2d21;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
} */
.card {
  border-style: none;
  border-radius: 20px;
}

.card-body {
  padding: 30px;
  box-shadow: 0px 34px 33px -23px rgba(22, 28, 45, 0.1295);
}

.card-title {
  color: rgba(16, 190, 169, 1);
  font-size: 15px;
  font-weight: 600;
}

.card-title span {
  background-color: rgba(16, 190, 169, 0.1);
  padding: 5px;
}

.card-text {
  color: rgba(92, 92, 92, 1);
  font-weight: 600;
}

.cardavail img {
  width: 70px;
  height: 60px;
  object-fit: contain;
}

.read {
  color: #0bb4a1 !important;
  font: Plus Jakarta Sans !important;
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.newsbtn {
  display: flex;
  justify-content: center;
}

.newshelpbtn {
  padding: 8px 45px 8px 45px;
  color: #11bfaa;
  width: auto;
  border: 1px solid #11bfaa;
  background-color: white;
  margin-top: 30px;
  border-radius: 20px;
  font-weight: 600;
}

.newshelpbtn:hover {
  color: white !important;
  background-color: #19a99c;
}

.dfghjk {
}

/* lets start */
.letstart {
  position: relative;
}

.letstart h3 {
  color: #5c5c5c;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.letstarthead {
  color: #0bb4a1;
}

.subscribe {
  display: flex;
  justify-content: center;
}

.subscribes {
  width: 380px;
  display: flex;
  justify-content: space-between;
  border: 2px solid #0bb4a1;
  padding: 5px;
  border-radius: 40px;
}

.subscribes input {
  border-style: none;
  width: 230px;
  margin-left: 10px;
  margin-bottom: 2px;
  padding: 7px !important;
}

.subscribes button {
  border-style: none;
  border: 1px solid #0bb4a1 !important;
  padding: 6px;
  background-color: #0bb4a1;
  color: white;
  border-radius: 30px;
  font-weight: 600;
  font-size: 13px;
}

.subscribes button:hover {
  background-color: white;
  border: none !important;
  color: #0bb4a1;
  border: 1px solid #0bb4a1 !important;
}

.subscribes input:focus {
  outline: none;
  border: none;
  color: black;
}

.let-start {
  padding-bottom: 80px;
  position: relative;
}

.imgarrow1 {
  position: absolute;
  top: 35%;
  left: 45px;
}

.imgarrow2 {
  position: absolute;
  top: 90px;
  right: 150px;
}

/*  */
