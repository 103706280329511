* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.aboutbanner {
  background-color: rgba(243, 251, 250, 1);
}
.aboutbanner h1 {
  color: rgba(92, 92, 92, 1);
  font-size: 3.2rem;
  font-weight: 900;
}
.aboutbanner span {
  color: rgba(16, 190, 169, 1);
}
.aboutbanner p {
  color: rgba(22, 28, 45, 1);
  letter-spacing: -1px;
}
/* about */
.about {
  margin-top: 80px;
}
.aboutusimg {
  border-radius: 40px;
}
.aboutright h3 {
  color: rgba(92, 92, 92, 1);
  font-size: 2.3rem;
  font-weight: 700;
}
.aboutusimg {
  float: right;
}
.aboutright {
  padding-left: 35px;
}
.aboutright span {
  color: rgba(16, 190, 169, 1);
}
.aboutright p {
  font-size: 15px;
  text-align: left !important;
  line-height: 25px;
  padding-right: 5px !important;
}
.aboutrightbtn {
  padding: 10px 40px 10px 40px;
  color: #10bea9;
  border: 1px solid #10bea9;
  background-color: white;
  border-radius: 20px;
  font-weight: 600;
}
.aboutrightbtn:hover {
  color: white !important;
  background-color: #10bea9;
}
/* ourvalues */
.homeourvalues {
  background-color: rgba(243, 251, 250, 1);
  margin-top: 80px;
  padding-top: 20px;
  padding-bottom: 40px;
}

.homevalues h3 {
  color: rgba(92, 92, 92, 1);
  font-size: 2.3rem;
  font-weight: 700;
  margin-top: 40px;
}
.homevalues span {
  color: rgba(16, 190, 169, 1);
}
/* box */
.firstrow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.secondrow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.firstpara {
  padding-left: 20px;
  padding-right: 50px;
  text-align: justify;
  line-height: 1.1;
}

.secondpara {
  padding-left: 20px;
  padding-right: 50px;
  text-align: justify;
  line-height: 1.3;
}
.firstpara h5 {
  font-weight: 800;
  color: rgba(92, 92, 92, 1);
}
.secondpara h5 {
  font-weight: 800;
  color: rgba(92, 92, 92, 1);
}
.firstpara p {
  color: rgba(100, 100, 100, 1);
}
.secondpara p {
  color: rgba(100, 100, 100, 1);
}
/* our mission */
.ourmisstop h3 {
  color: rgba(92, 92, 92, 1);
  font-size: 2.3rem;
  font-weight: 700;
  margin-top: 90px;
}
.ourmisstop span {
  color: rgba(16, 190, 169, 1);
}
.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #10bea9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-size: 15px;
  color: white;
  font-weight: 600;
}
.ourmisspara {
  text-align: left;
}
.ourmiss {
  display: flex;
  flex-direction: row;
}

.ourmisspara {
  padding-left: 15px;
}
.ourmissright {
  margin-top: 25%;
}
.ourmisspara h5 {
  font-weight: 700;
}
.ourmisspara p {
  color: rgb(0 0 0);
  padding-right: 12px;
  text-align: justify;
  line-height: 32px;
  font-size: 18px;
  font-weight: 500;
}
/* ourvision */
.ourvision {
  background-color: rgba(243, 251, 250, 1);
  /* height: 50vh; */
}
.ourvisright h3 {
  color: rgba(92, 92, 92, 1);
  font-size: 2.3rem;
  font-weight: 700;
}
.ourvisright span {
  color: rgba(16, 190, 169, 1);
}
.ourvisright {
  margin-top: 20%;
}
.ourvisrightimg {
  margin-top: 20%;
}
.ourvislefttimg {
  margin-top: 80px;
}
.lorem h4 {
  font-weight: 700;
  color: rgba(22, 28, 45, 1);
}
.lorem p {
  color: rgba(22, 28, 45, 1);
  padding-right: 42px;
}
.ourvisdown {
  margin-top: 10%;
}
.fontstart {
  background-color: #10bea9;
  padding: 10px;
  color: white;
  border-radius: 3px;
}


@media screen and (max-width: 1400px) {
  .circle {
    width: 90px;
        height: 33px;
    border-radius: 50%;
    background-color: #10bea9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text {
    font-size: 20px;
    color: white;
    font-weight: 600;
   
  }
  .ourmissright {
    margin-left: 10%;
  }
}
@media screen and (max-width: 992px) {
  .circle {
    width: 50px;
    height: 45px;
    border-radius: 50%;
    background-color: #10bea9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text {
    font-size: 20px;
    color: white;
    font-weight: 600;
  }
  .aboutright h3 {
    color: rgba(92, 92, 92, 1);
    font-size: 2.3rem;
    font-weight: 700;
    margin-top: 20px;
  }
  .aboutright {
    padding-left: 0px;
  }
  .aboutrightbtn {
    padding: 10px 40px 10px 40px;
    color: #10bea9;
    border: 1px solid #10bea9;
    background-color: white;
    border-radius: 20px;
    font-weight: 600;
  }
  .ourmissright {
    margin-top: 10%;
  }
  .ourmission {
    padding-bottom: 60px;
  }
  .ourmisspara h5 {
    font-weight: 700;
    font-size: 1.3rem;
  }
  .ourmisspara p {
    color: rgba(22, 28, 45, 1);
    padding-right: 18px;
    font-size: 1rem;
  }
  .ourvisright {
    margin-top: 15%;
  }
  .ourvisright h3 {
    color: rgba(92, 92, 92, 1);
    font-size: 2.3rem;
    font-weight: 700;
    text-align: center;
  }
  .ourvispara {
    text-align: center;
    font-size: 1.5rem;
  }
  .lorem p {
    color: rgba(22, 28, 45, 1);
    padding-right: 0px;
  }
  .ourmissright {
    margin-left: 0%;
  }
}
@media screen and (max-width: 600px) {
  .circle {
    width: 95px;
    /* height: 40px; */
    border-radius: 50%;
    background-color: #10bea9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text {
    font-size: 20px;
    color: white;
    font-weight: 600;
  }

  .ourmissright {
    margin-left: 0%;
  }

  .aboutright {
    padding: 10px !important;
  }
  .aboutright h3 {
    color: rgba(92, 92, 92, 1);
    font-size: 2.3rem;
    font-weight: 700;
    margin-top: 25px;
  }
  .aboutrightbtn {
    padding: 10px 40px 10px 40px;
    color: #10bea9;
    border: 1px solid #10bea9;
    background-color: white;
    border-radius: 20px;
    font-weight: 600;
    margin-left: 0%;
  }
  .mobtop {
    margin-top: 30px !important;
  }
  .firstrow {
    display: flex;
    flex-direction: column;
  }
  .firstpara {
    padding-left: 20px;
    padding-right: 0px;
    text-align: justify;
    line-height: 1.1;
    padding-left: calc(var(--bs-gutter-x) * 0) !important;
  }
  .secondrow {
    display: flex;
    flex-direction: column;
  }
  .secondpara {
    padding-left: 20px;
    padding-right: 0px;
    text-align: justify;
    line-height: 1.3;
    padding-left: calc(var(--bs-gutter-x) * 0) !important;
  }

  .ourvisdown {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
  }
  /* .selfabt {
    display: flex;
    justify-content: center;
  } */
  .firstpara h5 {
    font-weight: 800;
    color: rgba(92, 92, 92, 1);
    /* text-align: center; */
    margin-top: 15px;
  }
  .secondpara h5 {
    font-weight: 800;
    color: rgba(92, 92, 92, 1);
    /* text-align: center; */
    margin-top: 15px;
  }
  .ourvisright {
    margin-top: 15%;
  }
  .ourvispara {
    text-align: center;
    font-size: 1.1rem !important;
  }
}
