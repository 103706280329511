.Blogbanner {
  background-color: rgba(243, 251, 250, 1);
}
.Blogbanner h1 {
  color: rgba(92, 92, 92, 1);
  font-size: 3.2rem;
  font-weight: 900;
}
.Blogbanner span {
  color: rgba(16, 190, 169, 1);
}
.Blogbanner p {
  color: rgba(22, 28, 45, 1);
}
.blogtabs {
  background: linear-gradient(180deg, #10bea9 0%, #11bfaa 52.5%, #19a99c 100%);
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  justify-content: center;
}
.blogtabs p {
  color: white !important;
  text-decoration: none !important;
  padding-left: 40px;
  font-weight: 700;
  font-size: 20px;
}

/* .anchorWithWhiteBorder {
  border-bottom: 2px solid white;
  text-decoration: none;
} */
.tablink {
  text-decoration: none;
  color: rgba(92, 92, 92, 1) !important;
  font-weight: 600;
}

.blogtophead {
  font-weight: 700;
  color: rgba(92, 92, 92, 1);
}
.blogtophead span {
  color: #10bea9;
}
.scrollbar {
  /* height: 80vh; */
  /* width: 430px; */
  /* overflow: auto; */
}
.scrollimg img {
  width: 100%;
  height: 150px;
}
.scrollboxbot {
  justify-content: space-between;
}
.scrollboxbot p {
  color: rgba(92, 92, 92, 1) !important;
  font-size: 13px !important;
  font-weight: 800;
}
.scrollbar::-webkit-scrollbar {
  width: 5px;
}
.scrollbar::-webkit-scrollbar-track {
  background: rgb(255, 253, 253);
}
.scrollbar::-webkit-scrollbar-thumb {
  background: #10bea9;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #10bea9;
}
/*  */
.scrollcar span {
  color: rgba(16, 190, 169, 1);
  background-color: rgba(16, 190, 169, 0.1);
  font-weight: 700;
  padding: 5px;
  font-size: 1rem;
}
.scrollboxcon p {
  color: rgba(92, 92, 92, 1);
}
/* .sides {
  display: flex;
} */
.scrollboxbot p {
  font-size: 14px;
  color: rgba(119, 119, 119, 1);
  margin-bottom: 0px;
}
.scrollboxbot img {
  width: 20px;
  height: 20px;
}

/* recentpost */
.recpostcont h4 {
  color: rgba(92, 92, 92, 1);
  font-weight: 700;
}
.allpostbot p {
  font-size: 9px;
  color: rgba(119, 119, 119, 1);
  font-weight: 600;
  margin-bottom: 0px;
}
.calenicon {
  font-size: 11px;
}
.clockiconrecent {
  font-size: 11px;
}
.allblogread {
  cursor: pointer;
  text-decoration: underline;
}
.clockicon {
  font-size: 12px;
  margin-top: 2px;
}
.recentlypost {
  padding: 10px;
  border: 1px solid rgba(242, 242, 242, 1);
  border-radius: 5px;
  border: 1.03px solid rgba(231, 233, 237, 1);
}
.recentcontpara {
  font-size: 14px;
  color: rgba(85, 85, 85, 1);
}
/* allbligs card */
.card-title-allblogs span {
  color: #10bea9;
  background-color: rgba(16, 190, 169, 0.1);
  padding: 5px;
  font-size: 14px;
}
.card-text-allblogs {
  color: rgba(92, 92, 92, 1);
  font-size: 16px;
  font-weight: 600;
}
.allblogread {
  color: #10bea9 !important;
  font-size: 14px;
  font-weight: 600;
}
.card-all-blogs {
  border: 1.03px solid rgba(214, 221, 235, 1);
  border-radius: 5px;
}
.sides {
  box-shadow: 0px 34px 33px -23px rgba(22, 28, 45, 0.1295);
  /* border: 1px solid black; */
  border: 1.03px solid rgba(231, 233, 237, 1);
  padding: 20px 15px;
}
.blog-img-des {
  width: 100%;
  height: 30vh;
  object-fit: contain;
  padding-top: 15px;
}
/* @media screen and (max-width: 1200px) {
  .scrollbar {
    height: auto !important;
    width: auto !important;
    overflow: none;
  }
  .recentfrstflex {
    display: block !important;
  }
  .recentpostpng {
    padding-left: 25px;
  }

  .recpostcont {
    margin-top: 20px;
  }
  .allpostbot p {
    font-size: 11px;
  }
} */
.fieldinput {
  width: 100%;
  outline: none;
  border-radius: 8px;
  padding: 7px 15px;
  border: 1px solid #9195ab;
  margin-top: 5px;
}
.recentpostpng {
  width: 100%;
  object-fit: contain;
  height: 33vh;
}
@media screen and (max-width: 900px) {
  .blogtabs {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center;
  }
  .blogtabs p {
    margin-top: 10px !important;
    margin-left: 0px !important;
  }
  .blogtabs p {
    color: rgba(92, 92, 92, 1);
    text-decoration: none !important;
    padding-left: 0px !important;
    font-weight: 600;
  }
}

@media screen and (max-width: 1200px) {
  .recentfrstflex {
    display: block !important;
  }
  .recentfrstflex img {
    display: flex;
    justify-content: center;
  }
  .recpostcont {
    margin-top: 20px;
  }
  .allpostbot p {
    font-size: 10px;
  }
  .recentcontpara {
    padding-right: 0px !important;
  }
  .card-text-allblogs {
    font-size: 18px;
  }
  /* .recentpostpng {
    padding-left: 0px;
    width: 100%;
    height: 30vh;
  } */

  .scrollbar {
    height: auto;
    width: auto !important;
    overflow: none;
  }
  .scrollboxcon p {
    color: rgba(92, 92, 92, 1);
    font-size: 13px !important;
    font-weight: 800;
    padding-top: 4px;
  }
  .scrollboxbot p {
    font-size: 8px !important;
  }
  .scrollcar span {
    color: rgba(16, 190, 169, 1);
    background-color: rgba(16, 190, 169, 0.1);
    font-weight: 700;
    /* padding: 5px; */
    font-size: 12px !important;
  }
}
@media screen and (min-width: 1200px) {
  .scrollboxrighthead p {
    color: rgba(92, 92, 92, 1);
    font-size: 13px !important;
    font-weight: 800;
    padding-top: 4px;
    /* margin-left: 12px; */
  }
  .scrollboxbot p {
    font-size: 12px !important;
  }
  .scrollcar span {
    color: rgba(16, 190, 169, 1);
    background-color: rgba(16, 190, 169, 0.1);
    font-weight: 700;
    /* padding: 5px; */
    font-size: 12px !important;
  }
}
.NoblogImage {
  margin-top: 50px;
  height: 250px;
  width: 250px;
  margin-bottom: 20px;
  align-items: center;
}
.Blog-pro-image {
  width: 35px;
  height: auto;
}

.heigth-short-blog {
  height: 100px;
}
