.text-justify {
  text-align: justify;
}
.blog-detailed-img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.blog-de-para {
  text-align: justify;
  font-size: 14px;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.blog-img-des {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}
.specifichead {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}
.specifichead h4 {
  color: rgba(92, 92, 92, 1);
  font-weight: 700;
  font-size: 1.4rem;
}
.specifichead p {
  color: rgba(92, 92, 92, 1);
  font-weight: 700;
  font-size: 16px;
}
.specifichead img {
  width: 60px;
  height: auto;
}
.specificbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.specificbox {
  border: 1px solid rgba(214, 221, 235, 1);
  padding: 20px;
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
}

.specificbtn {
  padding: 6px 25px;
  background: linear-gradient(180deg, #10bea9 0%, #11bfaa 51%, #10bea9 100%);
  color: white;
  border: 1px solid #11bfaa;
  border-radius: 4px;
}
.Description p {
  color: rgba(92, 92, 92, 1);
}
.Description h4 {
  color: rgba(92, 92, 92, 1);
  font-weight: 700;
}
.Responsibilities h4 {
  color: rgba(92, 92, 92, 1);
  font-weight: 700;
}
.ticksicon {
  color: #10bea9;
  font-size: 20px;
}
.respoints {
  display: flex;
  /* flex-direction: column; */
}
.Abtrole h4 {
  color: rgba(92, 92, 92, 1);
  font-weight: 700;
}
.respoints p {
  color: rgba(81, 91, 111, 1);
}
.progress-bar {
  size: 20px;
}
.appliedprog {
  padding: 15px;
  background-color: rgba(16, 190, 169, 0.1);
}
.appliedprog p {
  font-weight: 700;
}
.applycont {
  display: flex;
  justify-content: space-between;
  color: rgba(81, 91, 111, 1);
}
.Abtrole {
  border-bottom: 1px solid rgba(214, 221, 235, 1);
}
.Categories h4 {
  color: rgba(92, 92, 92, 1);
  font-weight: 700;
}
.market {
  color: rgba(255, 184, 54, 1);
  font-weight: 700;
  background-color: rgba(235, 133, 51, 0.1);
  padding: 3px 6px;
  border-radius: 5px;
}
.desig {
  color: rgba(86, 205, 173, 1);
  font-weight: 700;
  background-color: rgba(86, 205, 173, 0.1);
  padding: 3px 6px;
  border-radius: 5px;
}
.perk {
  background-color: rgba(243, 251, 250, 1);
  padding: 30px 0px;
}
.perk h4 {
  color: rgba(92, 92, 92, 1);
  font-weight: 700;
}
.perkbox h6 {
  color: rgba(92, 92, 92, 1);
  font-weight: 700;
}
.perkbox p {
  color: rgba(92, 92, 92, 1);
}
.socialcont {
  display: flex;
}
.socialcont img {
  width: 60px;
  height: auto;
}
.socialcont h4 {
  margin-bottom: 0px;
  color: rgba(92, 92, 92, 1);
  font-weight: 700;
}
.socialpara {
  padding-top: 20px;
  color: rgba(81, 91, 111, 1);
}
.SocialMediaAssistant {
  padding: 110px 0px;
}
.redirect a {
  text-decoration: none;
  color: #10bea9;
  font-weight: 700;
}
.rightarrowicon {
  color: #10bea9;
  cursor: pointer;
}
.socialimg {
  padding: 60px 0px;
}
.socialimg img {
  width: 100%;
  height: auto;
}
.simjob {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.simjob h4 {
  color: rgba(92, 92, 92, 1);
  font-weight: 700;
}
.showallssim a {
  text-decoration: none;
  color: #10bea9;
  font-weight: 700;
}
.simboxcon h5 {
  color: rgba(92, 92, 92, 1);
  font-weight: 700;
}
.simboxcon p {
  color: rgba(81, 91, 111, 1);
}
.simflex p {
  font-size: 13px;
}
.simfull {
  background-color: rgba(86, 205, 173, 0.1);
  color: rgba(86, 205, 173, 1) !important;
  font-weight: 700;
  padding: 3px 6px;
  margin-right: 10px;
}
.simonsite {
  color: rgba(255, 184, 54, 1) !important;
  font-weight: 700;
  padding: 3px 6px;
  margin-right: 10px;
  border: 1px solid rgba(255, 184, 54, 1);
  border-radius: 5px;
}
.dollar {
  color: rgba(16, 190, 169, 1) !important;
  font-weight: 700;
  padding: 3px 6px;
  margin-right: 10px;
  border: 1px solid rgba(16, 190, 169, 1);
  border-radius: 5px;
}
.simbox {
  display: flex;
  border: 1px solid rgba(214, 221, 235, 1);
  padding: 15px 30px;
  border-radius: 8px;
}
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.copylink {
  margin-top: 5px;
  margin-bottom: 10px;
}
.copylink input {
  border: 1px solid rgba(159, 159, 159, 1);
  padding: 8px;
}

.copylink button {
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 20px 8px 20px;
  background-color: #10bea9;
  border: 1px solid #10bea9;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.share {
  display: flex;
  justify-content: space-between;
}
.share p {
  font-weight: 800;
  color: rgba(92, 92, 92, 1);
  font-size: 22px;
}
.cancelicon {
  font-size: 24px;
  margin-top: 4px;
  cursor: pointer;
  color: rgba(92, 92, 92, 1) !important;
}
.shareicon {
  font-size: 27px;
  color: #10bea9 !important;
  cursor: pointer;
}
.popimg {
  width: 80%;
  height: auto;
}
.social {
  display: flex;
  justify-content: space-evenly;
}
.sociallink p {
  font-size: 13px;
  color: rgba(92, 92, 92, 1);
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
  padding-top: 10px;
}
.share-content-child p {
  font-size: 14px;
  color: rgba(92, 92, 92, 1);
  font-weight: 600;
  margin-top: 6px;

}
.privacypara p{
 padding-bottom: 10px;
 font-size: 16px;
}

.privacyhead{
  padding-bottom: 30px;
  font-size: 24px;
  font-weight: 800;
 text-align: center;
  color: #0cb5a2;
}
.personaldata{
  padding-left: 60px;
}
.interhead{
  font-weight: 900;
  font-size: 24px;
  color: #0cb5a2;
}

@media screen and (max-width: 1023px) {
  .simflex p {
    font-size: 11px;
  }
  .SocialMediaAssistant {
    padding: 40px 10px 0px 10px;
  }
  .socialimg {
    padding: 20px 0px 30px 0px;
  }
}
@media screen and (max-width: 766px) {
  .simflex p {
    font-size: 11px;
  }
  .SocialMediaAssistant {
    padding: 30px 0px 0px 0px;
  }
  .socialimg {
    padding: 20px 0px;
  }
  .perk {
    background-color: rgba(243, 251, 250, 1);
    padding: 1px 0px;
  }
}

 