body {
  font-family: "Plus Jakarta Sans", sans-serif;
  --color: #0bb4a1;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.banner {
  background-color: rgba(243, 251, 250, 1);
}
.conabsolute {
  position: relative !important;
}
.conimgarrow1 {
  position: absolute;
  top: 25%;
  left: 2%;
}
.conimgarrow2 {
  position: absolute;
  top: 10%;
  right: 12%;
}
.banner h1 {
  color: rgba(92, 92, 92, 1);
  font-size: 3.2rem;
  font-weight: 900;
}
.banner span {
  color: rgba(16, 190, 169, 1);
}
.banner p {
  color: rgba(22, 28, 45, 1);
}
.info {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #10bea9 0%, #11bfaa 52.5%, #19a99c 100%);
  padding: 25px;
}
.sec1 {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-right: 40px;
}

.sec2 {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-right: 40px;
}
.sec3 {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-right: 40px;
 
}
.sec1 a {
  color: rgba(255, 255, 255, 1) !important;
  margin-bottom: 0px;
  margin-top: 0px !important;
  text-decoration: none !important;
  font-weight: 600;
}
.sec1 a:hover {
  color: black !important;
  margin-bottom: 0px;
  margin-top: 0px !important;
  text-decoration: none !important;
  font-weight: 600;
}
.conts {
  display: flex;
  flex-direction: column;
  align-items: center;
    justify-content: center;
}
.sec2 a {
  color: rgba(255, 255, 255, 1) !important;
  margin-bottom: 0px;
  margin-top: 0px !important;
  text-decoration: none !important;
  font-weight: 600;
}
.sec2 a:hover {
  color: black !important;
  margin-bottom: 0px;
  margin-top: 0px !important;
  text-decoration: none !important;
  font-weight: 600;
}
.sec3 p {
  color: rgba(255, 255, 255, 1) !important;
  margin-bottom: 0px;
  margin-top: 0px !important;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  display: flex;
}

.styleicon {
  background-color: white;
  padding: 10px;
  border-radius: 50px;
  aspect-ratio: 1;
  color: #0bb4a1;
}
/* form */
.table {
  box-shadow: 0px 30.84504508972168px 29.937837600708008px -20.865764617919922px
    rgba(22, 28, 45, 0.1295);
  border-radius: 10px;
  padding: 30px 20px;
  border: 0.91px solid rgba(231, 233, 237, 1);
}
.field label {
  color: rgba(22, 28, 45, 1);
  font-size: 13px;
  font-weight: 900;
}
.field input,
.field textarea {
  border: 1px solid rgba(217, 217, 217, 1);
  height: 45px;
  border-radius: 8px;
  padding: 14px;
  font-size: 13px;
}
.field input:focus {
  border: 1px solid rgb(43, 43, 43);
  outline: none;
}
.field input::placeholder {
  color: rgba(22, 28, 45, 1);
  padding: 0px;
  font-size: 13px;
}
.field textarea:focus {
  border: 1px solid rgb(43, 43, 43);
  outline: none;
  box-shadow: none;
}
.sendbtn {
  background-color: #0bb4a1;
  color: white;
  font-weight: 700;
  padding: 10px 65px;
  border-radius: 8px;
  border: 1px solid #0bb4a1;
}
.sendbtn:hover {
  background-color: white;
  color: #0bb4a1;
}
.contactfooter {
  margin-top: 90px;
}
.field input {
  /* width: 400px !important; */
}
.field label {
  margin-bottom: 5px;
}
.icon {
}
@media screen and (max-width: 1024px) {
  .conimgarrow1 {
    display: none !important;
  }
  .conimgarrow2 {
    display: none !important;
  }
  .sec1 {
    display: flex;
    flex-direction: row !important;
  }
  .sec1 a {
    font-size: 23px !important;
  }
  .sec2 {
    display: flex;
    flex-direction: row !important;
    margin-top: 10px;
  }
  .sec2 a {
    font-size: 23px !important;
  }
  .sec3 {
    display: flex;
    flex-direction: row !important;
    margin-top: 10px;
  }
  .sec3 p {
    font-size: 23px !important;
  }
  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(
      180deg,
      #10bea9 0%,
      #11bfaa 52.5%,
      #19a99c 100%
    );
    padding: 25px;
  }
  .field label {
    margin-bottom: 5px;
    font-size: 22px !important;
  }
  .field input::placeholder {
    color: rgba(22, 28, 45, 1);
    padding: 0px 10px;
    font-size: 20px !important;
  }
}
@media screen and (max-width: 600px) {
  .banner h1 {
    color: rgba(92, 92, 92, 1);
    font-size: 3.5rem !important;
    font-weight: 900;
  }
  .sec1 a {
    font-size: 20px !important;
  }
  .sec2 a {
    font-size: 20px !important;
  }
  .sec3 p {
    font-size: 20px !important;
  }
  .sec1 {
    display: flex;
    flex-direction: row !important;
    margin-right: 10px;
  }

  .sec2 {
    display: flex;
    flex-direction: row !important;
    margin-top: 10px;
    margin-right: 10px;
  }
  .sec3 {
    display: flex;
    flex-direction: row !important;
    margin-top: 10px;
    margin-right: 10px;
  }
  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(
      180deg,
      #10bea9 0%,
      #11bfaa 52.5%,
      #19a99c 100%
    );
    padding: 25px;
  }
}

@media screen and (min-width: 1400px) {
  .conabsolute {
    position: relative !important;
  }
  .conimgarrow1 {
    position: absolute;
    top: 25% !important;
    left: 6% !important;
  }
  .conimgarrow2 {
    position: absolute;
    top: 20% !important;
    right: 15% !important;
  }
}

.textDanger {
  color: #feba12;
  font-family: "OpenSans-Regular";
  font-size: 12px;
  margin-top: 4px;
}
.profileremove span {

  background-color: #f54c04;
  color: #fff;
  font-size: 15px;
  padding: 3px;
  border-radius: 50%;
  z-index: 5;
  position: absolute;
  top: 10px;
  right: 0px;
  cursor: pointer;

}
.hash {
  color: #feba12;
}
.modal-content {
  display: flex;
  border-radius: 20px;
  padding: 0px;
  width: 20rem;
}

.modal-content .header p:first-child {
  color: #2b3923;
  font-family: "OpenSans-Bold";
  font-size: 1.25rem;
}

.modal-content .fa-share-from-square {
  font-size: 18px;
  color: #feba12;
}

.modal-content .header p:nth-child(2) {
  font-size: 11px;
}
.share-btn button {
  border: none;
  background-color: #0bb4a1;
  color: #ffffff;
  width: auto;
  padding: 6px 38px;
  border-radius: 4px;
  font-family: "OpenSans-SemiBold";
  margin-top: 15px;
}
.green {
  color: #fff;
  padding: 10px;
}
.modal-content .header {
  background-color: rgba(16, 190, 169, 1);
  width: 100%;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.successData {
  margin-bottom: 10px;
  padding: 30px;
  text-align: "center";
}
