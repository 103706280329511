.rechead h4 {
  color: rgba(37, 50, 75, 1);
  font-weight: 800;
}
.recboxtop {
  display: flex;
  justify-content: space-between;
}
.reccombox1 {
  border: 1px solid rgba(214, 221, 235, 1);
  padding: 20px;
  height: 280px;
  border-radius: 5px;
}
.reccombox1 h5 {
  font-weight: 700;
  color: rgba(37, 50, 75, 1);
}
.reccombox1 p {
  color: rgba(81, 91, 111, 1);
  padding-right: 20px;
}
.recboxtop p {
  padding: 4px;
  background-color: rgba(16, 190, 169, 0.1);
  margin-bottom: 30px;
  font-weight: 600;
  color: #12c0ab;
}
.business {
  color: #12c0ab;
  border: 1px solid #12c0ab;
  padding: 5px 10px 7px 10px;
  border-radius: 25px;
  font-weight: 600;
}
.tech {
  color: rgba(255, 186, 10, 1);

  padding: 5px 10px 7px 10px;
  border-radius: 25px;
  font-weight: 600;
  background-color: rgba(255, 186, 10, 0.1);
}

/*  */
.compbycathead {
  display: flex;
  justify-content: space-between;
}
.compbycathead h4 {
  color: rgba(37, 50, 75, 1);
  font-weight: 900;
}
.scrollicon {
  color: white;
  background-color: rgba(92, 92, 92, 1);
  padding: 10px;
  border-radius: 5px;
}
.scrollowlback {
  /* background-color: black; */
  /* padding: 40px; */
}
/* .scrollowl1 {
  height: 50px;
  width: 90px;
} */
.scrollitem {
  width: 160px;
  height: 90px;
  border: 1px solid rgba(214, 221, 235, 1);
  background-color: white;
  padding: 10px;
  padding-bottom: 10px !important;
  border-radius: 10px;
}
.scrollitem:hover {
  background-color: #12c0ab;
}
.scrollitem:hover .owlscrollicon {
  color: white !important;
}
.scrollitem:hover p {
  color: white !important;
}
.scrollitem p {
  font-size: 14px;
  font-weight: 600;
}

/*  */
.results {
  font-weight: 600;
}

.resulticon {
  font-size: 30px;
}
.resulbox h6 {
  font-weight: 700;
  color: rgba(37, 50, 75, 1);
}
.resulbox p > span {
  color: #12c0ab;
  background-color: rgba(16, 190, 169, 0.1);
  padding: 8px;
  text-align: center;
}
.resulbox {
  border: 1px solid rgba(214, 221, 235, 1);
  padding: 10px;
  width: 250px;
  height: 200px;
  border-radius: 5px;
}
.viewmorecomp a {
  color: #12c0ab;
  text-decoration: none;
  font-weight: 700;
}
.owlscrollicon {
  color: #12c0ab;
  font-size: 22px;
}
.fghjk {
}
@media screen and (max-width: 992px) {
  .resulbox {
    border: 1px solid rgba(214, 221, 235, 1);
    padding: 10px;
    width: auto !important;
    height: 200px;
    border-radius: 5px;
  }
  .popularrght {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .scrollbar {
    height: 80vh;
    width: auto !important;
    overflow: auto;
  }
  .scrollboxbot p {
    font-size: 12px !important;
    color: rgba(119, 119, 119, 1);
    margin-bottom: 0px;
  }
}
