* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.jobbanner {
  background-color: rgba(243, 251, 250, 1);
}
.jobbanner h1 {
  color: rgba(92, 92, 92, 1);
  font-size: 3.2rem;
  font-weight: 900;
}
.jobbanner span {
  color: rgba(16, 190, 169, 1);
}
.jobbanner p {
  color: rgba(22, 28, 45, 1);
}
/* SEARCH BAR */
.jobcat {
  display: flex;
  flex-direction: row;

  /* justify-content: space-between; */
}
.jobloc {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  border: 1px solid #12c0ab;
  border-radius: 40px;
  background-color: white;
}

.searchrow {
  border: 2px solid rgba(16, 190, 169, 1);
  padding: 8px;
  background-color: white !important ;
  border-radius: 45px;
}
.inp1 input {
  border-style: none;
}
.inp1 input:focus {
  outline: none;
  border: none;
  color: black;
}
.inp1 {
  padding-top: 10px;
  margin-left: 20px;
  /* border-right: 2px solid #12c0ab; */
  padding-right: 90px;
}
.brdrght {
  border-right: 2px solid #12c0ab;
}
.inp2 {
  padding-top: 10px;
  margin-left: 12px;
}

.jobcategoryicon {
  background-color: #0bb4a1;
  aspect-ratio: 1;
  border-radius: 50px;
  color: white;
  padding: 15px;
}
.joblocicon {
  background-color: #0bb4a1;
  aspect-ratio: 1;
  border-radius: 50px;
  color: white;
  padding: 14px;
}
.inp2 input:focus {
  outline: none;
  border: none;
  color: black;
}
.inp2 input {
  border-style: none;
}
.jobsrcbtn {
  padding: 5px 14px 10px 13px;
  border-style: none;
  background-color: rgba(16, 190, 169, 1);
  border-radius: 30px;
  color: white;
  font-weight: 600;
  /* margin-left: 95px; */
  text-align: center;
}
/* .jobsrcbtn:hover {
  background-color: white;
  color: #0bb4a1;
} */
.jobsicon {
  /* text-align: center; */
  margin-top: 6px;
  margin-right: 6px;
  font-size: 17px;
}
.pagesec {
  display: flex;
  justify-content: center;
}
.findjobs p {
  background-color: rgba(16, 190, 169, 1);
  color: white;
  padding: 7px 20px 9px 20px;
  border-radius: 25px;

  font-weight: 600;
  text-decoration: none;
}
.findjobs p:hover {
  background-color: white !important;
  color: black;
}

.BrowseComp a {
  background-color: rgba(16, 190, 169, 1);
  color: white;
  padding: 7px 20px 9px 20px;
  border-radius: 25px;

  font-weight: 600;
  text-decoration: none;
}

.BrowseComp a:hover {
  background-color: rgb(253, 254, 254) !important;
  color: black;
}
/*  */
.typeofemp input {
  height: 17px;
  width: 30px;
  border: 2px solid rgba(214, 221, 235, 1);
}

.accordion-item {
  background-color: #fff;
  border: none;
}
.accordion-button:not(.collapsed) {
  color: black;
  font-weight: 600;
  background-color: white;
  box-shadow: none;
  border: none;
}
.accordion-button:focus {
  border-color: none;
  box-shadow: none;
  border: none;
}
.accordion-body {
  padding: 0px;
  padding: 15px 5px;
  border: none;
  border-top: none;
}
/* RIGHTSIDE ALL JOBS */
.righttop {
  display: flex;
  justify-content: space-between;
}
.alljob h4 {
  color: rgba(37, 50, 75, 1);
  font-weight: 800;
}
.alljob p {
  color: rgba(124, 132, 147, 1);
  font-size: 12px;
}
.sortby select {
  font-weight: 600;
  border-style: none;
}

.filtericon2 {
  font-size: 20px;
  color: #12c0ab;
}
.filtericon1 {
  font-size: 20px;
  color: rgba(81, 91, 111, 1);
}
/* BOX */

.jobcont h5 {
  font-weight: 700;
  color: rgba(37, 50, 75, 1);
}
.jobappbtn {
  background-color: rgba(16, 190, 169, 1);
  color: white;
  font-weight: 700;
  padding: 10px 60px 12px 60px;
  border-radius: 5px;
  border-style: none;
}
.jobappbtn:hover {
  background-color: white;
  color: #0bb4a1;
}
.alljobsjob {
  display: flex;
}
.fulltmbox1 {
  padding: 5px 10px 7px 10px;
  background-color: rgba(86, 205, 173, 0.1);
  font-weight: 600;
  color: rgba(16, 190, 169, 1);
}
.fulltmbox2 {
  padding: 5px 10px 5px 10px;
  background-color: white;
  font-weight: 600;
  color: rgba(255, 184, 54, 1);
  border: 2px solid rgba(255, 184, 54, 1);
  border-radius: 5px;
  margin-left: 10px;
}
.fulltmbox3 {
  padding: 3px 10px 5px 10px;
  background-color: white;
  font-weight: 600;
  color: rgba(16, 190, 169, 1);
  border: 2px solid rgba(16, 190, 169, 1);
  border-radius: 5px;
  margin-left: 10px;
}
.recentcontpara {
  font-weight: 700;
  color: rgba(37, 50, 75, 1);
}
.progress {
  width: 93%;
}
.jobbox1 {
  display: flex;
  flex-direction: column;
  border: 2px solid rgba(214, 221, 235, 1);
  padding: 25px 25px 10px 25px;
}
.alljobsjob h5 {
  font-weight: 700;
  color: rgba(37, 50, 75, 1);
}
.alljobsjob p {
  font-weight: 700;
  color: rgba(37, 50, 75, 1);
}
.progress-bar {
  background-color: rgba(16, 190, 169, 1);
  font-weight: 600;
}

/*  */
.page-link {
  border-style: none;
  color: black;
  font-weight: 600;
  border: 1px solid black;
  margin-right: 20px;
  border-radius: 5px;
  padding: 5px 15px;
  cursor: pointer;
  margin-bottom: 0px;
}
.page-link span > a {
  color: black !important;
}
.page-link span > a:hover {
  color: rgb(255, 255, 255) !important;
}

.page-link:hover {
  background-color: #12c0ab;
  color: white;
  border-radius: 5px !important;
  padding: 5px 15px !important;
  border: 1px solid #12c0ab;
}
.page-link:focus {
  border-color: none;
  box-shadow: none;
}
.jobdes {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.jobjpg img {
  width: 70px;
  height: 60px;
  object-fit: contain;
}
.fghj {
}
/* @media screen and (min-width: 767px) {
  .jobsrcbtn {
    padding: 5px 12px 8px 12px;
    border-style: none;
    background-color: rgba(16, 190, 169, 1);
    border-radius: 30px;
    color: white;
    font-weight: 600;
    margin-left: 0px;
    text-align: center;
    margin-top: 5px !important;
  }
  .jobloc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
} */
@media screen and (max-width: 992px) {
  .searchrow {
    border: none;
    padding: 8px;
    background-color: rgba(243, 251, 250, 1) !important;
    border-radius: 45px;
    justify-content: center !important;
  }
  .jobloc {
    display: flex;
    flex-direction: row;
    padding-left: 0px !important;
    border: 1px solid #12c0ab;
    border-radius: 40px;
    justify-content: space-between;
    background-color: white;
  }
  .searchrgt {
    /* border: 2px solid #12c0ab !important; */
    padding: 9px !important;
    border-radius: 50px !important;
    background-color: #fff;
    /* margin-top: 20px !important; */
    /* margin-right: 20px !important; */
    /* width: 100%; */
  }
  .inp1 input {
    border-style: none;
    /* margin-right: 10px !important; */
    width: 135%;
    padding: 13px;
    /* background-color: ; */
    /* border: 2px solid #12c0ab; */
    border-radius: 30px;
  }
  .inp2 input {
    border-style: none;
    margin-left: 0px;
    width: 160%;
    /* padding: 13px; */
    /* border: 2px solid #12c0ab;
    border-radius: 30px; */
  }
  .inp2 input:focus {
    border: none !important;
  }
  .inp2 {
    margin-right: 0px;
    padding-top: 10px;
    padding-left: 0px;
  }
  .jobcat {
    /* display: block; */
    border: 2px solid #12c0ab !important;
    padding: 7px;
    border-radius: 50px !important;
    background-color: #fff;
    margin-left: 12px !important;
  }
  .inp1 {
    padding-top: 0px;
    padding-right: 0px;
    border-right: none;
  }

  .inp1 input:focus {
    border: none !important;
  }
  .jobsearch {
    /* display: flex; */
    justify-content: center;
    /* margin-top: 15px; */
  }
  .jobsrcbtn {
    padding: 5px 16px 8px 16px;
    border-style: none;
    background-color: rgba(16, 190, 169, 1);
    border-radius: 40px;
    color: white;
    font-weight: 600;
    text-align: center;
    margin-top: 12px;
    margin-right: 14px;
  }
  .jobcategoryicon {
    display: block;
    margin-left: 5px !important;
    margin-top: 3px;
  }
  .joblocicon {
    display: block;
    /* margin-left: 5px !important; */
  }
  .jobsearchicon {
    display: none;
  }
  .brdrght {
    border-right: none;
  }

  .righttop {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 16px;
  }

  /* Alljobs */
  .brdrght {
    padding-left: calc(var(--bs-gutter-x) * 0);
  }
  .inp2 {
    /* margin-left: 30px !important; */
  }
  .alljobsjob {
    display: block;
  }
  .jobcont {
    margin-left: 11px !important;
  }
  .jobjpg {
    display: flex;
    /* justify-content: center; */
    padding-bottom: 20px;
    margin-left: 15px;
  }
  .jobbox1 {
    display: block;
    justify-content: space-between;
    border: 2px solid rgba(214, 221, 235, 1);
    padding: 15px;
    box-shadow: #0bb4a1 !important;
  }
  .fulltmbox1 {
    padding: 5px;
    background-color: rgba(86, 205, 173, 0.1);
    font-weight: 600;
    color: rgba(16, 190, 169, 1);
  }
  .fulltmbox2 {
    padding: 5px;
  }
  .fulltmbox3 {
    padding: 5px;
  }
  /* .alljobsjob {
    display: flex;
    flex-direction: column;
  } */
  .progress {
    width: 48%;
  }
  .mobres {
    margin-top: 20px;
    margin-left: 3%;
  }
  .righttop {
    display: block;
  }
  .alljob {
    margin-top: 20px;
    margin-left: 5px;
  }
  .sortby select {
    font-weight: 600;
    border-style: none;
    background-color: white;
    /* background-color: white;
    border: 2px solid #12c0ab;
    padding: 5px;
    border-radius: 10px; */
    /* padding-right: 7px; */
  }
}
@media screen and (max-width: 767px) {
  .inp1 input {
    border-style: none;
    /* margin-right: 10px !important; */
    width: 100% !important;
    padding: 13px;
    /* background-color: ; */
    /* border: 2px solid #12c0ab; */
    border-radius: 30px;
  }
  .jobjpg {
    display: flex;
    /* justify-content: center; */
    padding-bottom: 20px;
  }
  .inp2 input {
    border-style: none;
    margin-left: 0px;
    width: 100% !important;
    /* padding: 13px; */
    /* border: 2px solid #12c0ab;
  border-radius: 30px; */
  }
}
@media screen and (max-width: 391px) {
  .jobsicon {
    display: none;
  }
}
@media screen and (max-width: 415px) {
  .fulltmbox1 {
    padding: 0px;
    background-color: rgba(86, 205, 173, 0.1);
    font-weight: 600;
    color: rgba(16, 190, 169, 1);
  }
  .fulltmbox2 {
    padding: 0px;
    background-color: white;
    font-weight: 600;
    color: rgba(255, 184, 54, 1);
    border: 2px solid rgba(255, 184, 54, 1);
    border-radius: 5px;
    margin-left: 5px;
  }
  .fulltmbox3 {
    padding: 0px;
    background-color: white;
    font-weight: 600;
    color: rgba(16, 190, 169, 1);
    border: 2px solid rgba(16, 190, 169, 1);
    border-radius: 5px;
    margin-left: 5px;
  }
}
@media screen and (min-width: 992px) {
  .jobsrcbtn {
    padding: 5px 16px 8px 16px;
    border-style: none;
    background-color: rgba(16, 190, 169, 1);
    border-radius: 40px;
    color: white;
    font-weight: 600;
    text-align: center;
    margin-top: 12px;
    margin-right: 14px;
  }
  .jobloc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .searchrgt {
    padding: 9px !important;
    border-radius: 50px !important;
    background-color: #fff;
  }
}
.seemore-less-btn {
  border: 0px;
  background-color: #fff;
}

.seemore-less-btn span.text {
  font-size: 14px;
  color: rgba(85, 85, 85, 1);
}