* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.footer {
  background-color: #000000;
  padding: 40px;
}
.ft1 {
  margin-left: 70px;
}
.ft1 p {
  color: #ffffff;
  text-align: justify;
  font-size: 20px;
}

.ft2 a {
  margin-top: 200px;
}

.footer h4 {
  color: #ffffff;
}
.footer ul {
  list-style-type: none;
}
.secondpartul div {
  text-align: left;
  font-size: 13px;
  color: #ffffff;
  text-decoration: none;
  list-style: none;
  cursor: pointer;
}
.secondpartul a {
  text-align: left;
  font-size: 13px;
  color: #ffffff;
  text-decoration: none;
  list-style: none;
}
.secondpartul a:hover {
  color: #0bb4a1;
}

.secondpartul {
  padding-left: 0px;
}
.secondpartul div:hover {
  color: #0bb4a1;
}

.otherlogos img {
  padding: 12px;
  cursor: pointer;
}
.anchor {
  text-decoration: none;
}

hr {
  color: white;
}
.lastpara {
  color: #ffffff;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
}
.otherlogos img:hover {
  background-color: #0bb4a1;
  border-radius: 10px;
}
.otherlogos {
  display: flex;
}
@media screen and (max-width: 768px) {
  .ft1 {
    margin-left: 0px !important;
  }
  .footer {
    background-color: #000000;
    padding: 0px !important;
  }
  .ft1 {
    margin-top: 40px !important;
  }
  .secondpart1 {
    margin-top: 20px !important;
  }
  .secondpart2 {
    margin-top: 30px !important;
  }
  .secondpart3 {
    margin-top: 30px !important;
  }
}
@media screen and (min-width: 768px) {
  .footerdiv {
    display: flex !important;
    flex-direction: row !important;
    margin-top: 50px !important;
    margin-left: 10px !important;
  }
  .ft1 {
    margin-left: 0px !important;
  }
  .secondpart1 {
    margin-top: 0px !important;
  }
  .secondpart2 {
    margin-left: 150px !important;
  }
  .secondpart3 {
    margin-left: 150px !important;
  }
}
@media screen and (min-width: 1024px) {
  .secondpart2 {
    margin-left: 5px !important;
  }
  .secondpart3 {
    margin-left: 68px !important;
  }
  .footerdiv {
    margin-left: 40px !important;
    margin-top: 0px !important;
  }
}
