.subboxtop {
  display: flex;
  justify-content: space-between;
}
.subboxtop p1 {
  color: #12c0ab;
  background-color: rgba(16, 190, 169, 0.1);
  font-weight: 600;
  padding: 4px;
  margin-bottom: 30px;
}
.browssubbox h5 {
  color: rgba(37, 50, 75, 1);
  font-weight: 600;
}
.browssubbox p {
  color: rgba(81, 91, 111, 1);
}
.browsubbs1 {
  padding: 4px 8px 5px 8px;
  border: 1px solid #12c0ab;
  color: #12c0ab;
  border-radius: 20px;
  font-weight: 600;
}
.browsubbs2 {
  padding: 4px 10px 5px 10px;
  background-color: rgba(255, 186, 10, 0.1);
  color: rgba(255, 186, 10, 1);
  border-radius: 20px;
  font-weight: 600;
}
.browssubbox {
  padding: 25px;
  border: 1px solid rgba(214, 221, 235, 1);
  border-radius: 5px;
}
/*  */
/* .page-linksub {
  border-style: none;
  color: black;
  font-weight: 600;
}
.page-linksub:hover {
  background-color: #12c0ab;
  border-radius: 5px;
  color: white;
}
.page-linksub:focus {
  border-color: none;
  box-shadow: none;
} */
