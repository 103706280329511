.adminlogin {
  /* background-color: #F3FBFA; */
  height: 100vh;
  display: flex;
  align-items: center;
}

.adminlogin .container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logininput input {
  padding: 10px 10px 10px 45px;
  border-radius: 8px;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.logininput span {
  position: absolute;
  padding: 12px;
}

.loginlayout {
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 80%;
  border-radius: 10px;
}

.login-btn {
  padding: 8px;
  width: 100%;
  border-radius: 8px;
  background-color: #10bea9;
  color: #fff;
  border: none;
}

.login-imgs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
}

.adminlogin .row>div:first-child {
  background-color: #f3fbfa;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.adminlogin .row>div:nth-child(2) {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.login-imgs img:first-child {
  margin-bottom: 4rem;
}

.adminlogin .row>div:nth-child(2) p:first-child {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 3rem;
}

.forgot-password {
  text-align: end;
  margin-top: 10px;
  cursor: pointer;
}

.fa-eye,
.fa-eye-slash {
  position: relative;
  padding: 12px;
  float: right;
  top: -41px;
  cursor: pointer;
}

.tss-1x5mjc5-MUIDataTable-root {
  height: 65vh;
  overflow: scroll;
}

.logininput {
  margin-bottom: 2rem;
}

.star {
  color: #feba12;
}

.descript {
  font-weight: 800 !important;
  font-size: 16px !important;
}


.messagecontent {
  background-color: #f0f0f0;
  color: #0bb4a1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 15px;
  width: 100%;

}

.contact-form-admin-modal {
  width: 30rem !important;
}

.contact-form-admin-modal .close-icon {
  margin-top: 10px;
  position: absolute;
  top: 0;
  background: #0bb4a1;
  color: #ffffff;
  padding: 3px 3px 3px 3px;
  border-radius: 30px;
  width: fit-content;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
}

.popupmess {
  padding-bottom: 20px;
  text-align: center;
  color: #000;
}

/* .field .form label {
  font-weight: 700;
} */

/* =================================================(Side bar) ================================================ */

.sidebar {
  position: absolute;
  /* background-color: #F3FBFA; */
  height: 86vh;
  width: 17rem;
}

.sidebar ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.swal2-confirm {
  background-color: #10bea9 !important;
}

.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-cancel:focus {
  box-shadow: none !important;
}

.sidebar li {
  margin: 13px 0px 0px 0px;
}

.sidebar a {
  text-decoration: none;
  font-size: 16px;
}

.sidebar a>div {
  display: flex;
  background-color: white;
  padding: 10px 0px 10px 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.sidebar a>div:hover {
  display: flex;
  background-color: #0bb4a1;
  padding: 11px 0px 11px 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.sidebar a.active>div {
  display: flex;
  background-color: #0bb4a1;
  padding: 13px 0px 15px 10px;
  border-radius: 5px;
  color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.sidebar a.active>div p {
  color: #ffffff;
}

.sidebar a.active>div span {
  color: #ffffff;
}

.sidebar li:hover p {
  color: #ffffff;
}

.sidebar li:hover span {
  color: #ffffff;
}

.sidebar-menuActive>span {
  margin-right: 0.7rem;
  color: black;
  font-size: 19px;
}

.sidebar-menuActive p {
  color: black;
  font-weight: 600;
  /* background-color: #0bb4a1; */
  padding-top: 2px;
  font-size: 15px;
}

.sidebar-menuInActive>span {
  margin-right: 0.7rem;
  color: black;
}

.sidebar-menuInActive p {
  color: black;
  font-weight: 600;
  /* background-color: #0bb4a1; */
}

/* =================================================(page-content)================================================ */

.page-content {
  margin-left: 17rem;
  height: 86vh;
  background-color: #fafafa;
  border-radius: 10px;
  position: absolute;
  overflow: hidden;
  width: -webkit-fill-available;
}

.content-body {
  margin: 0px 50px;
  padding: 50px 50px 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 10px;
  height: 70vh;
  overflow: scroll;
}

.content-body .fa-eye,
.content-body .fa-eye-slash {
  position: absolute;
  padding: 12px;
  top: 2rem;
  cursor: pointer;
  right: 0;
}

/* =================================================(admin-header)================================================ */

.admin-header {
  /* background-color: #F3FBFA; */
  top: 0;
  padding: 0px 20px;
  height: 14vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .admindrop {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(-136px, 55px);
} */
/* .admin-header .profile {
 
  height: 50px;
  width: 50px;
  border-radius: 50%;
  cursor: pointer;
} */
.admin-header .profile img {
  height: 50px;
  width: 50px;
  object-fit: contain;
  border-radius: 50%;
  cursor: pointer;
}

.admindrop li {
  font-weight: 700;
}

.admindrop {
  border: 1px solid rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}

.dropdown-toggle::after {
  display: none;
}

.admin-header li {
  cursor: pointer;
  padding: 3px 3px;
  border-radius: 3px;
}

.admin-header ul {
  padding: 14px;
  width: max-content;
}

.admin-header li:hover {
  /* background-color: #10bea9; */
  color: #10bea9;
}

/* =================================================(MUIDatatable)================================================ */

.MuiPaper-root {
  margin: 0px 50px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  border-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.action-icons {
  font-size: 20px;
}

/* .tss-1qtl85h-MUIDataTableBodyCell-root .action-icons {
  display: flex !important;
} */
.jobicons {
  display: flex;
}

.action-icons .fa-eye {
  position: relative;
  padding: 0;
  float: none;
  top: 0;
}

.action-icons>i {
  margin: 0px 10px;
  cursor: pointer;
}

.fa-trash {
  color: red;
}

.fa-trash:hover {
  color: red !important;
}

.action-icons>i:hover {
  color: #10bea9;
}

.tss-1qtl85h-MUIDataTableBodyCell-root {
  text-align: center;
}

.tss-178gktx-MUIDataTableHeadCell-contentWrapper {
  justify-content: center;
}

.css-ee20y-MuiPaper-root {
  height: 65vh;
  overflow-y: scroll;
}

.table-catogory-height .css-ee20y-MuiPaper-root {
  height: 55vh;
  overflow-y: scroll;
}

.MuiToolbar-root p {
  margin-bottom: 0px;
}

.content-header>p {
  font-size: 1.5rem;
  font-weight: bold;
  /* color: #10BEA9; */
  margin-bottom: 0;
}

.content-header {
  margin: 0px 50px;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-btn {
  border: none;
  border-radius: 8px;
  background-color: #10bea9;
  padding: 10px 20px;
  color: #f3fbfa;
}

.admin-btn:hover {
  border: 1px solid #10bea9;
  background-color: #f3fbfa;
  color: #10bea9;
}

.filter-tabs button {
  border: 1px solid #10bea9;
}

/* ========================================(Forgot-password-page)============================================ */
.forgot-password-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.forgot-input>input {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 12px 12px 12px 40px;
  border-radius: 8px;
  outline: none;
  border: none;
  width: 100%;
  margin-top: 10px;
}

.forgot-input .fa-envelope,
.forgot-input .fa-key {
  position: absolute;
  padding: 24px 11px;
  font-size: 21px;
}

.forgot-password-page img {
  margin-bottom: 20px;
}

.forgot-header {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 3rem;
}

.forgot-password-page button {
  margin-top: 20px;
  width: 100%;
}

.forgot-password-page>div {
  width: 28rem;
  padding: 60px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 8px;
}

.validation-error {
  color: red;
}

.resend-otp {
  cursor: pointer;
}

/* ========================================(Create form)============================================ */
.create-input label {
  margin-bottom: 8px;
  font-weight: 700;
}

.create-input {
  margin-bottom: 20px;
  position: relative;
}

.star-mark {
  color: #feba12;
}

.create-input input,
.create-input textarea,
.create-input select {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid lightgray;
}

.error-space {
  height: 20px;
}

.fa-circle-left {
  cursor: pointer;
}

.fa-circle-left:hover {
  color: #10bea9;
}

.note {
  font-style: italic;
  font-size: 13px;
}

.max-hint {
  font-style: italic;
  font-size: 12px;
}

/* ========================================(Toggle button)============================================ */

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #10bea9;
}

input:focus+.slider {
  box-shadow: 0 0 1px #10bea9;
}

input:checked+.slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* ========================================(Custom Modal)============================================ */

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.btn-delete {
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 8px 22px;
  background-color: #10bea9;
  color: white;
}

.delete-modal .btn-delete:hover {
  border: 1px solid #10bea9;
  background-color: #fff;
  color: #10bea9;
}

.btn-cancel {
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 8px 22px;
  background-color: #fff;
}

.delete-modal .btn-cancel:hover {
  border: 1px solid #000000;
  background-color: rgb(233, 233, 233);
  color: black;
}

.delete-modal {
  padding: 20px;
}

.delete-confirm {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.icon-container {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 22px;
}

.icon-container:hover {
  color: #10bea9;
}

.tab-section ul {
  display: flex;
  background-color: #c3f4ed;
  padding: 5px;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  gap: 0px !important;
  list-style-type: none;
  align-items: center;
  margin-bottom: 0px;
  width: fit-content;
}

.tab-section {
  margin: 0px 50px;
  width: 100%;
}

.serviceTab {
  padding: 7px 15px 10px;
  border-radius: 10px;
  cursor: pointer;
  margin: 2px 10px;
}

.serviceTab.active {
  background-color: #ffffff;
  color: #000;
  font-weight: 700;
  font-size: 14px;
  /* box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2); */
}

.serviceTab.inactive {
  background-color: #50454500;
  color: #000;
  font-weight: 500;
  font-size: 14px;
}

.cursor {
  cursor: pointer;
}

.profileimage {
  height: 70px;
  width: 70px !important;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.custom-imgupload img {
  width: 30px;
  height: auto;
  cursor: pointer;
}

.order-input {
  width: 30%;
}

.alt-btn {
  background-color: #10bea9;
  color: white;
  border-radius: 8px;
  padding: 8px 20px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 15px;
}

.alt-btn:hover {
  background-color: white;
  color: #10bea9;
  border: 1px solid #213626;
}

.uploadprofile img {
  /* background-color: red; */
  border-radius: 50%;

  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.uploadsvg {
  color: white;
  font-size: 15px;
  cursor: pointer;
  position: absolute;
  background-color: #10bea9;
  border-radius: 50%;
  padding: 8px;
  left: 65px;
  top: 72px;
}

.myprofile {
  /* background-color: green; */
  position: relative;
}

/* .page404 {
  background-color: #10bea9;
} */
.page404 img {
  width: 50vh;
  height: auto;
}

.page404 {
  margin-top: 40px;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.page404 button {
  color: white;
  background: linear-gradient(180deg, #0bb4a1 0%, #11bfaa 50.52%, #1ca498 100%);
  border: 1px solid #10bea9;
  padding: 8px 20px;
  font-weight: 700;
  font-size: 13px;
  border-radius: 50px;
}

.page404 button:hover {
  color: #0bb4a1;
  background: white;
  border: 1px solid #10bea9;
  padding: 8px 20px;
  font-weight: 700;
  font-size: 13px;
  border-radius: 50px;
}

.backerror {
  background-color: rgba(243, 251, 250, 1);
}

.modal-content-childlist {
  height: 55vh;
  overflow-y: auto;
}

/* width */
.modal-content-childlist::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.modal-content-childlist::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.modal-content-childlist::-webkit-scrollbar-thumb {
  background: #0bb4a1;
  border-radius: 10px;
}

/* Handle on hover */
.modal-content-childlist::-webkit-scrollbar-thumb:hover {
  background: #0bb4a1;
}

.modal-content-childlist td p {
  font-size: 14px;
  color: #000;
  padding-right: 5px;
}

.modal-content-childlist th .text-heading-tab {
  font-size: 15px;
  color: #000;
  font-weight: 700;
  text-wrap: nowrap;
}

.modal-content-childlist th {
  vertical-align: baseline;
  padding: 5px;
}

.firstrow p,
.secondrow p {
  line-height: 25px;
  font-size: 15px;
  text-align: left;
  margin-bottom: 0px;
}

.blog-body-content img {
  align-self: center;
}

.rdw-link-modal{
  height: auto !important;
}